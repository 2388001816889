import { getSystemCurrency } from 'helpers/commonFunctions';
import React from 'react';
import ReactApexChart from "react-apexcharts";


const Mixed = ({categories,values, dataColors } : any) => {
    // var chartMultiColors = getChartColorsArray(dataColors);
    console.log(values)

    var options : any = {
        chart: {
            height: 350,
            stacked: false,
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                barHeight: '100%',
                distributed: false,
                horizontal: false,
                dataLabels: {
                    position: 'top'
                },
            }
        },
        dataLabels: {
            enabled: true,
        },
        stroke: {
            width: 1,
            colors: ['#fff']
        },
        xaxis: {
            categories: categories
        },
        yaxis: [
            {
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: "#038edc",
                },
            },
        ],
        tooltip: {
            theme: 'dark',
            x: {
                show: true
            },
            y: {
                title: {
                    formatter: function () {
                        return '';
                    }
                }
            }
        }
        // colors: chartMultiColors,
    };

    return (
        <React.Fragment>
            <ReactApexChart dir="ltr"
                className="apex-charts"
                options={options}
                series={values}
                type="line"
                height={350}
            />
        </React.Fragment>
    );
};

export default Mixed