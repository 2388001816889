import { useEffect, useState } from 'react';
import {
    Container,
    Spinner,
    Pagination,
    PaginationItem,
    PaginationLink,
} from "reactstrap";
import { toast } from "react-toastify";
import getApi from "../../apis/get.api";
import { checkPermission } from 'helpers/commonFunctions';

const ListStatements = () => {
    document.title = "Wallet | Account Statements";
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [showInformation,setShowInformation] = useState<boolean>(false);
    const canViewAccountStatementReport = checkPermission("/wallet/statements");

    const fetch = (page : number) => {
        setLoading(true);
        let url = `/merchant/wallet/transactions/list`;
        getApi(url).then((response) => {
            const data = response.data.data;
            setList(data.data);
            setCurrentPage(data.current_page);
            setLastPage(data.last_page);
        })
        .catch((error) => {
            toast.error('There was an issue while fetching the wallet transactions list');
        })
        .finally(() => {
            setLoading(false);
        });
    };

    useEffect(() => {
        if(canViewAccountStatementReport){
            fetch(currentPage);
        }else{
            setLoading(false)
        }
    }, [currentPage,canViewAccountStatementReport]);

    const handlePageChange = (page : number) => {
        if (page !== currentPage) {
            setCurrentPage(page);
        }
    };

    function tog_center() {
        setShowInformation(!showInformation);
    }

    const getTransactionType = (transactionType : string,customTransactionId : string) => {
        let type = 'Redeem'
        if(customTransactionId){
            type = customTransactionId
        }
        switch (type) {
            case 'fund':
                type = 'Redeem'
                break;
            case 'deduct':
                type = 'Settlement'
                break;
            case 'payout':
                type = 'Payout'
                break;
            case 'settlement':
                type = 'Settlement'
                break;
            default:
                break;
        }
        return type;
    }

    return (
        <div className="page-content">
            <Container fluid={true}>
                <div className="rounded-3">
                    <h1 className="display-5 fw-bold">Account Statements</h1>
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header fs-4">
                                    In this page you will find all statements transactions that has been made on your account
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive table-card mt-4">
                                        <table
                                            className="table text-center align-middle table-nowrap table-striped-columns mb-0">
                                            <thead className="table-light">
                                            <tr>
                                                <th scope="col" className={"text-center"}>Unique ID</th>
                                                <th scope="col">Transaction ID</th>
                                                <th scope="col">Transaction Type</th>
                                                <th scope="col">Amount</th>
                                                <th scope="col">Currency</th>
                                                <th scope="col">Amount Before</th>
                                                <th scope="col">Amount After</th>
                                                <th scope="col">Transaction Date</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                {loading && (
                                                    <tr>
                                                        <td className="text-center p-4" colSpan={8}><Spinner/></td>
                                                    </tr>
                                                )}
                                                {list && list.length > 0 && canViewAccountStatementReport && list.map((transaction: any,index : number) => (
                                                    <tr key={index} className={"fw-bold"}>
                                                        <td className={"text-center"}><span
                                                            className="fw-medium">#{transaction.transactionUnique}</span>
                                                        </td>
                                                        <td>{transaction.transactionId}</td>
                                                        <td>{getTransactionType(transaction.transactionType,transaction.customTransactionType)}</td>
                                                        <td className={transaction.transactionType === 'fund' ? 'text-success' : 'text-danger'}>{transaction.transactionType === 'fund' ? '+' : '-'}{transaction.transactionAmount}</td>
                                                        <td>{transaction.transactionCurrency}</td>
                                                        <td>{transaction.transactionBalanceBefore}</td>
                                                        <td>{transaction.transactionBalanceAfter}</td>
                                                        <td>{transaction.transactionDate}</td>
                                                    </tr>
                                                ))}
                                                {!loading && list.length === 0 && canViewAccountStatementReport &&  (
                                                    <tr>
                                                        <td className="text-center p-4" colSpan={8}>No Transactions Found
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                    {list && list.length > 0 && <div className="card-footer d-flex justify-content-center mt-4 border-top-0">
                                        <Pagination>
                                            {/* Previous Button */}
                                            <PaginationItem disabled={currentPage === 1}>
                                                <PaginationLink previous
                                                                onClick={() => handlePageChange(currentPage - 1)}/>
                                            </PaginationItem>

                                            {/* Pagination Numbers */}
                                            {[...Array(lastPage)].map((_, i) => {
                                                // Display first two pages, last two pages, and pages near the current page
                                                const pageNumber = i + 1;
                                                const isNearCurrentPage = Math.abs(currentPage - pageNumber) <= 2;
                                                const isFirstOrLastPage = pageNumber === 1 || pageNumber === lastPage;

                                                if (isNearCurrentPage || isFirstOrLastPage) {
                                                    return (
                                                        <PaginationItem key={pageNumber}
                                                                        active={currentPage === pageNumber}>
                                                            <PaginationLink
                                                                onClick={() => handlePageChange(pageNumber)}>
                                                                {pageNumber}
                                                            </PaginationLink>
                                                        </PaginationItem>
                                                    );
                                                }
                                                // Ellipsis for skipped pages
                                                if (pageNumber === 2 && currentPage > 4) {
                                                    return <PaginationItem key="ellipsis1"
                                                                           disabled><PaginationLink>...</PaginationLink></PaginationItem>;
                                                }
                                                if (pageNumber === lastPage - 1 && currentPage < lastPage - 3) {
                                                    return <PaginationItem key="ellipsis2"
                                                                           disabled><PaginationLink>...</PaginationLink></PaginationItem>;
                                                }

                                                return null; // Don't render other page numbers
                                            })}

                                            {/* Next Button */}
                                            <PaginationItem disabled={currentPage === lastPage}>
                                                <PaginationLink next onClick={() => handlePageChange(currentPage + 1)}/>
                                            </PaginationItem>
                                        </Pagination>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default ListStatements;