import React, { useEffect, useState } from "react";
import {
    Label, Modal, ModalBody, ModalFooter, ModalHeader,
    Spinner
} from "reactstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import getApi from "apis/get.api";
import postApi from "../../../apis/post.api";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import {bo} from "@fullcalendar/core/internal-common";
import {boolean} from "yup";
import { handleError, handleSuccess } from "helpers/commonFunctions";

interface Props {
    showModal: boolean;
    closeModal(): void;
    forceFetch() : void
}

const CreateReseller = ({ showModal, closeModal,forceFetch }: Props) => {
    const [countries, setCountries] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [phoneError, setPhoneError] = useState<string>("");
    const [errors, setErrors] = useState<any>({});
    const [reseller, setReseller] = useState<any>({
        email: '',
        username: '',
        website: '',
        country_id: '',
        password: '',
        confirm_password: '',
        phone_number: '',
        countryCode: "",
    });

    useEffect(() => {
        getApi('/common/get/countries').then((response) => {
            const countriesList = response.data.data.map((country: any) => ({
                label: country.name,
                value: country.id,
            }));
            setCountries(countriesList);
        }).catch((error) => {
            toast.error('General error occurred');
            throw error;
        });
    }, []);

    const changeCountry = (event: any) => {
        setReseller({
            ...reseller,
            country_id: event.value,
        });
        setErrors((prevErrors: any) => ({ ...prevErrors, country_id: '' }));
    };

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setReseller({
            ...reseller,
            [name]: value
        });
        setErrors((prevErrors: any) => ({ ...prevErrors, [name]: '' }));
    };

    const validateForm = () => {
        const newErrors: any = {};

        if (!reseller.username) newErrors.username = "Username is required";
        if (!reseller.email) newErrors.email = "Email is required";
        if (!reseller.website) newErrors.website = "Website is required";
        if (!reseller.country_id) newErrors.country_id = "Country is required";
        if (!reseller.password) newErrors.password = "Password is required";
        if (reseller.password !== reseller.confirm_password) newErrors.confirm_password = "Passwords do not match";
        if (!reseller.phone_number || phoneError) newErrors.phone_number = "Valid phone number is required";

        setErrors(newErrors);

        // Logging errors to see if they are correctly assigned
        console.log("Validation Errors:", newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleCreate = () => {
        if (!validateForm()) {
            console.log("Form validation failed:", errors);
            return;  // Exit if validation fails
        }
        setLoading(true);
        postApi('/merchant/reseller/create', reseller)
        .then(response => {
            handleSuccess(response)
            closeModal();
            forceFetch()
        })
        .catch(error => {
            handleError(error)
        })
        .finally(() => {
            setLoading(false);
        });
    };

    const handlePhoneChange = (isValid: boolean, value: string) => {
        setReseller({
            ...reseller,
            phone_number: value,
        });
        setPhoneError(!isValid ? 'Phone number is invalid' : '');
    };

    const handleCountryChange = (currentCountry: string, newCountry: any) => {
        setReseller({
            ...reseller,
            phone_number : "",
            country_code: newCountry.dialCode,
        });
    };


    const isCreateButtonDisabled = !reseller.username || !reseller.email || !reseller.website ||
        !reseller.country_id || !reseller.password ||
        reseller.password !== reseller.confirm_password ||
        !reseller.phone_number || phoneError;

    return (
        <Modal isOpen={showModal} size={'md'} toggle={closeModal} centered>
            <ModalHeader className="border-4 pb-3">
                Create Reseller
            </ModalHeader>
            <ModalBody>
                <div>
                    <Label>Reseller Name</Label>
                    <input
                        className="w-100 form-control"
                        placeholder="Reseller Name"
                        name="username"
                        value={reseller.username}
                        onChange={handleInputChange}
                    />
                    {errors.username && <small className="text-danger">{errors.username}</small>}
                </div>
                <div className="mt-2">
                    <Label>Reseller Email</Label>
                    <input
                        className="w-100 form-control"
                        placeholder="Reseller Email"
                        name="email"
                        value={reseller.email}
                        onChange={handleInputChange}
                    />
                    {errors.email && <small className="text-danger">{errors.email}</small>}
                </div>
                <div className="mt-2">
                    <Label>Reseller Website</Label>
                    <input
                        className="w-100 form-control"
                        placeholder="Reseller Website"
                        name="website"
                        value={reseller.website}
                        onChange={handleInputChange}
                    />
                    {errors.website && <small className="text-danger">{errors.website}</small>}
                </div>
                <div className="mt-2">
                    <Label>Reseller Phone Number</Label>
                    <IntlTelInput
                        containerClassName="intl-tel-input  w-100"
                        inputClassName="w-100 form-control"
                        autoHideDialCode={false}
                        nationalMode={true}
                        onPhoneNumberChange={(isValid, value) =>
                            handlePhoneChange(isValid, value)
                        }
                        onSelectFlag={(currentNumber,selectedCountryData) =>
                            handleCountryChange(currentNumber, selectedCountryData)
                        }
                        value={reseller.phone_number}
                    />
                    {phoneError && <small className="text-danger">{phoneError}</small>}
                </div>
                <div className="mt-2">
                    <Label>Select Country</Label>
                    <Select
                        options={countries}
                        className="w-100"
                        onChange={changeCountry}
                    />
                    {errors.country_id && <small className="text-danger">{errors.country_id}</small>}
                </div>
                <div className="mt-2">
                    <Label>Password</Label>
                    <input
                        type="password"
                        className="w-100 form-control"
                        placeholder="Password"
                        name="password"
                        value={reseller.password}
                        onChange={handleInputChange}
                    />
                    {errors.password && <small className="text-danger">{errors.password}</small>}
                </div>
                <div className="mt-2">
                    <Label>Confirm Password</Label>
                    <input
                        type="password"
                        className="w-100 form-control"
                        placeholder="Confirm Password"
                        name="confirm_password"
                        value={reseller.confirm_password}
                        onChange={handleInputChange}
                    />
                    {errors.confirm_password && <small className="text-danger">{errors.confirm_password}</small>}
                </div>
            </ModalBody>
            <ModalFooter className="border-4 pt-2">
                <div className="hstack gap-2 justify-content-end">
                    <button type="button" className="btn btn-danger" onClick={closeModal}>Close</button>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleCreate}
                        disabled={isCreateButtonDisabled ? true : loading}
                    > {loading ? <Spinner size="sm" /> : 'Create Reseller'}
                    </button>
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default CreateReseller;
