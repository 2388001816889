import { useEffect, useState } from 'react';
import {
    Card,
    CardBody,
    Container,
    Label,
    Nav,
    NavItem,
    NavLink,
    Spinner,
    TabContent,
    TabPane,
    Button,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
} from "reactstrap";
import { toast } from "react-toastify";
import getApi from "../../apis/get.api";
import classnames from "classnames";
import DualListBox from "react-dual-listbox";
import "react-dual-listbox/lib/react-dual-listbox.css";
import postApi from 'apis/post.api';
import { checkPermission, handleError, handleSuccess } from 'helpers/commonFunctions';
import Select from "react-select";

const Permissions = () => {
    document.title = "Wallet | Permissions";
    const [roles, setRoles] = useState([]);
    const [systemPermissions, setSystemPermissions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedOptGroup, setSelectedOptGroup] = useState<any>([]);
    const [roleName, setRoleName] = useState(""); // New state for role name
    const canViewAccountStatementReport = checkPermission("/permissions");
    const [activeTab, setactiveTab] = useState<any>("1");
    const [optgroup, setOptgroup] = useState([]);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedRole, setSelectedRole] = useState<any>(null);
    const [selectedOptGroupToEdit, setSelectedOptGroupToEdit] = useState<any>([]);
    const [roleSelect, setRolesSelect] = useState([]);
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userPassword, setUserPassword] = useState('');
    const [errors, setErrors] = useState<any>({});
    const [userRoleId, setUserRoleId] = useState<number>(0);
    const [selectUserToEdit, setSelectUserToEdit] = useState<any>(null);
    const [usersList, setUsersList] = useState<any>(null);
    const [showEditUserModal, setShowEdiUserModal] = useState(false);
    const [userNameToEdit, setUserNameToEdit] = useState('');
    const [userEmailToEdit, setUserEmailToEdit] = useState('');
    const [userPasswordToEdit, setUserPasswordToEdit] = useState('');
    const [selectedUserRole, setSelectedUserRole] = useState<any>({
        label : 'Select User Role',
        value : 0
    });
    
    const toggle = (tab:any) => {
        if (activeTab !== tab) {
            setactiveTab(tab);
        }
    };

    // Check if the form is valid for enabling the button
    const isFormValid = () => {
        return roleName.trim() !== "" && selectedOptGroup.length > 0;
    };

    const handleRoleNameChange = (e: any) => {
        setRoleName(e.target.value);
    };

    useEffect(() => {
        fetchPermissionPageSetting()
        fetchUsers()
    },[]);

    const fetchUsers = () => {
        getApi('/merchant/user/list').then((response) => {
            setUsersList(response.data.data);
        }).catch((error) => {
            handleError(error);
        })
    }

    const fetchPermissionPageSetting = () => {
        setLoading(true);
        getApi('/merchant/permissions/list').then((response) => {
            setRoles(response.data.data.roles);
            setSystemPermissions(response.data.data.permissions);
            const permissionsAttached : any = [];
            response.data.data.permissions.forEach((permission : any) => {
                permissionsAttached.push({
                    value : permission.name,
                    label : permission.label,
                    id : permission.id
                });
            });
            const rolesSelectData : any = [];
            response.data.data.roles.forEach((role: any) => {
                rolesSelectData.push({
                    label : role.name,
                    value : role.id
                })
            })
            setRolesSelect(rolesSelectData)
            setOptgroup(permissionsAttached);
        }).catch((error) => {
            let errorMsg = 'There was an issue while fetching the wallet transactions list'
            toast.error(errorMsg);
        }).finally(() => {
            setLoading(false);
        });
    }

    const change = (event: any) => {
        if (Array.isArray(event)) {
            setSelectedOptGroup(event);
        } else {
            setSelectedOptGroup([...selectedOptGroup, event]);
        }
    };

    const handleCreateRole = () => {
        if (isFormValid()) {
            postApi('/merchant/role/create',{roleName: roleName,permissionsList : selectedOptGroup}).then((response) => {
                setSelectedOptGroup([])
                setRoleName('')
                toast.success(`"${roleName}" has been created successfully`)
                fetchPermissionPageSetting()
            }).catch((error) => {
                let errorMsg = `There was an issue while creating "${roleName}"`
                if(error.response.data && error.response.data.message){
                    errorMsg = error.response.data.message
                }
                toast.error(errorMsg)
            })
        }
    };

    const handleShowModal = (role : any) => {
        setShowEditModal(!showEditModal);
        setSelectedRole(role)
        const permissionsAttached : any = [];
        role.permissions.forEach((permission : any) => {
            permissionsAttached.push(permission.name)
        });
        setSelectedOptGroupToEdit(permissionsAttached);
    }

    const changeSelectedRole = (event: any) => {
        if (Array.isArray(event)) {
            setSelectedOptGroupToEdit(event);
        } else {
            setSelectedOptGroupToEdit([...selectedOptGroupToEdit, event]);
        }
    };

    const editRole = () => {
        postApi('/merchant/role/edit',{roleId : selectedRole.id,permissions : selectedOptGroupToEdit,roleName : selectedRole.name}).then(response => {
            handleSuccess(response)
            fetchPermissionPageSetting()
            setSelectedRole(null)
            setSelectedOptGroupToEdit([])
            setShowEditModal(!showEditModal);
        }).catch(error => {
            handleError(error)
        })
    }

    const handleRoleName = (event : any) => {
        setSelectedRole({ 
            ...selectedRole,
            name: event.target.value 
        });
    };

    const handleUserNameChange = (event : any) => {
        setUserName(event.target.value);
    };

    const handleUserEmailChange = (event : any) => {
        setUserEmail(event.target.value);
    };

    const handleUserPasswordChange = (event : any) => {
        setUserPassword(event.target.value);
    };

    const validateInputs = () => {
        const newErrors : any = {};
        if (!userName) newErrors.userName = "User Name is required.";
        if (!userEmail) newErrors.userEmail = "User Email is required.";
        if (!roleName) newErrors.roleName = "Role Name is required.";
        if (!userPassword) {
            newErrors.userPassword = "User Password is required.";
        } else if (userPassword.length < 6) {
            newErrors.userPassword = "Password must be at least 6 characters long.";
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; // Return true if no errors
    };

    const handleCreateUser = () => {
        if (!validateInputs()) return; // If validation fails, do not proceed
        postApi("/merchant/user/create",{
            userName,
            userEmail,
            roleName,
            userRoleId,
            userPassword,
        }).then((response) => {
            setUserRoleId(0)
            setRoleName('')
            setUserPassword('')
            setUserName('')
            setUserEmail('')
            setSelectedUserRole({
                label : 'Select User Role',
                value : 0
            })
            fetchUsers()
            handleSuccess(response);
        }).catch((err) => {
            handleError(err)
        })
    };

    const selectUserRole = (role : any) => {
        setUserRoleId(role.value)
        setRoleName(role.label)
        setSelectedUserRole({
            label : role.label,
            value : role.value
        })
    }

    const editUser = (user : any) => {
        setUserNameToEdit(user.userName)
        setUserEmailToEdit(user.userEmail)
        setSelectUserToEdit(user)
        setSelectedUserRole({
            label : user.userRoleName,
            value : user.userRoleId
        })
        setShowEdiUserModal(true);
    }
    
    const handleEditUserName = (event : any) => {
        setUserNameToEdit(event.target.value)
    }

    const handleEditUserEmail = (event : any) => {
        setUserEmailToEdit(event.target.value)
    }

    const handleEditUserPassword = (event : any) => {
        setUserPasswordToEdit(event.target.value)
    }

    const handleEditUser = () => {
        if (!validateInputsEdit()) return; // If validation fails, do not proceed
        postApi("/merchant/user/edit",{
            userIdToEdit : selectUserToEdit.userId,
            userEmailToEdit,
            roleName,
            userRoleId,
            userPasswordToEdit,
            userNameToEdit,
            updateEmail : selectUserToEdit.userEmail !== userEmailToEdit
        }).then((response) => {
            setUserRoleId(0)
            setRoleName('')
            setUserPasswordToEdit('')
            setUserNameToEdit('')
            setUserEmailToEdit('')
            setSelectedUserRole({
                label : 'Select User Role',
                value : 0
            })
            fetchUsers()
            handleSuccess(response);
            setShowEdiUserModal(false)
        }).catch((err) => {
            handleError(err)
        })
    };

    const validateInputsEdit = () => {
        const newErrors : any = {};
        if (!userNameToEdit) newErrors.userNameToEdit = "User Name is required.";
        if (!userEmailToEdit) newErrors.userEmailToEdit = "User Email is required.";
        if (!roleName) newErrors.roleName = "Role Name is required.";
        if (userPasswordToEdit && userPasswordToEdit.length < 6) {
            newErrors.userPasswordToEdit = "Password must be at least 6 characters long.";
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; // Return true if no errors
    };

    return (
        <div className="page-content">
            <Container fluid={true}>
                <div className="rounded-3">
                    <h1 className="display-5 fw-bold">Permissions</h1>
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <Card>
                                <CardBody>
                                    <Nav tabs className="nav-tabs mb-3">
                                        <NavItem>
                                            <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "1", })} onClick={() => { toggle("1"); }} >
                                                Roles
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "2", })} onClick={() => { toggle("2"); }} >
                                                Permissions
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "3", })} onClick={() => { toggle("3"); }} >
                                                Create New Role
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "4", })} onClick={() => { toggle("4"); }} >
                                                Users List
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "5", })} onClick={() => { toggle("5"); }} >
                                                Create New User
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <TabContent activeTab={activeTab} className="text-muted">
                                        <TabPane tabId="1" id="roles" className="">
                                            <div className='row mt-2'>
                                                {roles && roles.map((role: any,index : number) => (
                                                    <div className='col-lg-3 col-12' key={index}>
                                                        <div className='card shadow-lg'>
                                                            <div className="card-body">
                                                                <p className="mb-1">Role : {role.name}</p>
                                                                <p className="mb-0">Permissions : {role.permissions.length}</p>
                                                            </div>
                                                            <div className='card-footer'>
                                                                {role.name !== 'SuperAdmin' && <button className="btn btn-primary btn-sm w-100" onClickCapture={() => handleShowModal(role)}>Edit Role</button>}
                                                                {role.name === 'SuperAdmin' && <button className="btn btn-primary btn-sm w-100" disabled >No Action</button>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </TabPane>
                                        <TabPane tabId="2" id="permissions">
                                            <div className="table-responsive table-card mt-4">
                                                <table className="table align-middle table-nowrap table-striped-columns mb-0">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th scope="col">Permission</th>
                                                            <th scope="col">Permission Description</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {loading && (
                                                            <tr>
                                                                <td className="text-center p-4" colSpan={1}><Spinner/></td>
                                                            </tr>
                                                        )}
                                                        {systemPermissions && systemPermissions.map((systemPermission: any,index : number) => (
                                                            <tr key={index} className={"fw-bold"}>
                                                                <td>{systemPermission.label}</td>
                                                                <td>{systemPermission.description}</td>
                                                            </tr>
                                                        ))}
                                                        {!loading && !canViewAccountStatementReport &&  (
                                                            <tr><td className="text-center p-4" colSpan={1}>You don't have permission to view system roles</td></tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </TabPane>
                                        <TabPane tabId="3" id="newRole">
                                            {optgroup && (
                                                <div className="row">
                                                    <div className='col-12'>
                                                        <Label>Role Name</Label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder='Role Name'
                                                            value={roleName}
                                                            onChange={handleRoleNameChange} // Handle role name change
                                                        />
                                                    </div>
                                                    <div className="col-12 mt-3">
                                                        <DualListBox
                                                            canFilter
                                                            filterCallback={(optgroup: any, filterInput: any) => {
                                                                if (filterInput === "") {
                                                                    return true;
                                                                }
                                                                return new RegExp(filterInput, "i").test(optgroup.label);
                                                            }}
                                                            filterPlaceholder="Search for permission"
                                                            options={optgroup}
                                                            selected={selectedOptGroup}
                                                            onChange={(e : any) => change(e)}
                                                            icons={{
                                                                moveLeft: <span className="mdi mdi-chevron-left" key="key"/>,
                                                                moveAllLeft: [
                                                                    <span className="mdi mdi-chevron-double-left" key="key"/>,
                                                                ],
                                                                moveRight: <span className="mdi mdi-chevron-right" key="key"/>,
                                                                moveAllRight: [
                                                                    <span className="mdi mdi-chevron-double-right" key="key"/>,
                                                                ],
                                                                moveDown: <span className="mdi mdi-chevron-down" key="key"/>,
                                                                moveUp: <span className="mdi mdi-chevron-up" key="key"/>,
                                                                moveTop: (
                                                                    <span className="mdi mdi-chevron-double-up" key="key"/>
                                                                ),
                                                                moveBottom: (
                                                                    <span className="mdi mdi-chevron-double-down" key="key"/>
                                                                ),
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='col-12 mt-3'>
                                                        <Button
                                                            type="button"
                                                            color="primary"
                                                            disabled={!isFormValid()} // Disable button if form is invalid
                                                            onClick={handleCreateRole} // Handle create role action
                                                        >
                                                            Create Role
                                                        </Button>
                                                    </div>
                                                </div>
                                            )}
                                        </TabPane>
                                        <TabPane tabId="4" id="usersList" className="">
                                            <div className="table-responsive table-card mt-4">
                                                <table className="table align-middle table-nowrap table-striped-columns mb-0">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th scope="col">Username</th>
                                                            <th scope="col">Email</th>
                                                            <th scope="col">Permission</th>
                                                            <th scope="col">Status</th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {usersList && usersList.map((user: any,index : number) => (
                                                            <tr key={index} className={"fw-bold"}>
                                                                <td>{user.userName}</td>
                                                                <td>{user.userEmail}</td>
                                                                <td>{user.userRoleName}</td>
                                                                <td>{user.userStatus ? 'ACTIVE' : 'INACTIVE'}</td>
                                                                <td>
                                                                    <button className="btn btn-primary btn-sm" onClick={()=>{editUser(user)}}>Edit User</button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        {!loading && !canViewAccountStatementReport &&  (
                                                            <tr><td className="text-center p-4" colSpan={5}>No users attached to your account</td></tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </TabPane>
                                        <TabPane tabId="5" id="newUser">
                                            <div className="row">
                                                <div className='col-lg-3 col-12'>
                                                    <Label>User Name</Label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder='User Name'
                                                        value={userName}
                                                        onChange={handleUserNameChange}
                                                    />
                                                    {errors.userName && <div className="text-danger">{errors.userName}</div>}
                                                </div>
                                                <div className='col-lg-3 col-12'>
                                                    <Label>User Email</Label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder='User Email'
                                                        value={userEmail}
                                                        onChange={handleUserEmailChange}
                                                    />
                                                    {errors.userEmail && <div className="text-danger">{errors.userEmail}</div>}
                                                </div>
                                                <div className='col-lg-3 col-12'>
                                                    <Label>Role Name</Label>
                                                    <Select
                                                        value={selectedUserRole} // Set this to the appropriate value for react-select
                                                        options={roleSelect}
                                                        onChange={selectUserRole}
                                                        name="choices-single-default"
                                                        id="role"
                                                    />
                                                    {errors.roleName && <div className="text-danger">{errors.roleName}</div>}
                                                </div>
                                                <div className='col-lg-3 col-12'>
                                                    <Label>User Password</Label>
                                                    <input
                                                        type="password" // Use password type for security
                                                        className="form-control"
                                                        placeholder='User Password'
                                                        value={userPassword}
                                                        onChange={handleUserPasswordChange}
                                                    />
                                                    {errors.userPassword && <div className="text-danger">{errors.userPassword}</div>}
                                                </div>
                                                <div className='col-12 mt-3'>
                                                    <Button
                                                        type="button"
                                                        color="primary"
                                                        // disabled={!isFormValidCreate()} // Disable button if form is invalid
                                                        onClick={handleCreateUser} // Handle create user action
                                                    >
                                                        Create User
                                                    </Button>
                                                </div>
                                            </div>
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </div>
                {selectedRole && <Modal isOpen={showEditModal} size={'lg'} toggle={() => {setShowEditModal(false)}} centered>
                    <ModalHeader className="border-4 pb-3">
                        Edit Role
                    </ModalHeader>
                    <ModalBody className="">
                        <div className="g-3 row">
                            <div className="col-12">
                                <label className="form-label form-label">
                                    Role Name
                                </label>
                                <input
                                    name="roleName"
                                    id="roleName"
                                    type="text"
                                    className="form-control"
                                    aria-invalid="false"
                                    placeholder="100"
                                    onChange={handleRoleName}
                                    value={selectedRole.name}
                                />
                            </div>
                            <div className='col-12'>
                                <DualListBox
                                    canFilter
                                    key={'inner'}
                                    filterCallback={(optgroup: any, filterInput: any) => {
                                        if (filterInput === "") {
                                            return true;
                                        }
                                        return new RegExp(filterInput, "i").test(optgroup.label);
                                    }}
                                    filterPlaceholder="Search for permission"
                                    options={optgroup}
                                    selected={selectedOptGroupToEdit}
                                    onChange={(e : any) => changeSelectedRole(e)}
                                    icons={{
                                        moveLeft: <span className="mdi mdi-chevron-left" key="key"/>,
                                        moveAllLeft: [
                                            <span className="mdi mdi-chevron-double-left" key="key"/>,
                                        ],
                                        moveRight: <span className="mdi mdi-chevron-right" key="key"/>,
                                        moveAllRight: [
                                            <span className="mdi mdi-chevron-double-right" key="key"/>,
                                        ],
                                        moveDown: <span className="mdi mdi-chevron-down" key="key"/>,
                                        moveUp: <span className="mdi mdi-chevron-up" key="key"/>,
                                        moveTop: (
                                            <span className="mdi mdi-chevron-double-up" key="key"/>
                                        ),
                                        moveBottom: (
                                            <span className="mdi mdi-chevron-double-down" key="key"/>
                                        ),
                                    }}
                                />
                            </div>
                            <div className="col-12">
                                <button className={'btn btn-primary w-100'} onClick={editRole} disabled={false}>Edit Role </button>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>}
                {selectUserToEdit && <Modal isOpen={showEditUserModal} size={'md'} toggle={() => {setShowEdiUserModal(false)}} centered>
                    <ModalHeader className="border-4 pb-3">
                        Edit User
                    </ModalHeader>
                    <ModalBody className="">
                        <div className="row">
                            <div className='col-12'>
                                <Label>User Name</Label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder='User Name'
                                    value={userNameToEdit}
                                    onChange={handleEditUserName}
                                />
                                {errors.userNameToEdit && <div className="text-danger">{errors.userNameToEdit}</div>}
                            </div>
                            <div className='col-12 mt-2'>
                                <Label>User Email</Label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder='User Email'
                                    value={userEmailToEdit}
                                    onChange={handleEditUserEmail}
                                />
                                {errors.userEmailToEdit && <div className="text-danger">{errors.userEmailToEdit}</div>}
                            </div>
                            <div className='col-12 mt-2'>
                                <Label>Role Name</Label>
                                <Select
                                    value={selectedUserRole} // Set this to the appropriate value for react-select
                                    options={roleSelect}
                                    onChange={selectUserRole}
                                    name="choices-single-default"
                                    id="role"
                                />
                                {errors.roleName && <div className="text-danger">{errors.roleName}</div>}
                            </div>
                            <div className='col-12 mt-2'>
                                <Label>User Password</Label>
                                <input
                                    type="password" // Use password type for security
                                    className="form-control"
                                    placeholder='User Password'
                                    value={userPasswordToEdit}
                                    onChange={handleEditUserPassword}
                                />
                                {errors.userPasswordToEdit && <div className="text-danger">{errors.userPasswordToEdit}</div>}
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            className='w-100'
                            type="button"
                            color="primary"
                            onClick={handleEditUser}>
                            Edit User
                        </Button>
                    </ModalFooter>
                </Modal>}
            </Container>
        </div>
    );
};

export default Permissions;
