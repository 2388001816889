import React, { useEffect, useState } from 'react';
import {
    Container,
    Spinner,
    Pagination,
    PaginationItem,
    PaginationLink, ModalHeader, Modal, ModalBody,
} from "reactstrap";
import { toast } from "react-toastify";
import getApi from "../../apis/get.api";
import {PendingSettlement, Settlement} from "../../Interfaces/Interface";
import { checkPermission, handleError } from 'helpers/commonFunctions';

const ListPendingSettlements = () => {
    document.title = "Wallet | Account Pending Settlements";
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [showInformation,setShowInformation] = useState<boolean>(false);
    const canViewPendingSettlements = checkPermission("/wallet/settlements/pending")

    const fetch = (page : number) => {
        setLoading(true);
        let url = `/merchant/wallet/settlements/pending`;
        if(page){
            url += `?&page=${page}`;
        }
        getApi(url)
            .then((response) => {
                const data = response.data.data;
                setList(data.data);
                setCurrentPage(data.current_page);
                setLastPage(data.last_page);
            })
            .catch((error) => {
                handleError(error)
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if(canViewPendingSettlements){
            fetch(currentPage);
        }else{
            setLoading(false)
        }
    }, [currentPage,canViewPendingSettlements]);

    const handlePageChange = (page : number) => {
        if (page !== currentPage) {
            setCurrentPage(page);
        }
    };

    function tog_center() {
        setShowInformation(!showInformation);
    }

    return (
        <div className="page-content">
            <Container fluid={true}>
                <div className="rounded-3">
                    <h1 className="display-5 fw-bold">Account Pending Settlements</h1>
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header fs-4">
                                    In this page you will find all settlements transactions that has been made on your account
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive table-card">
                                        <table
                                            className="table text-center align-middle table-nowrap table-striped-columns mb-0">
                                            <thead className="table-light">
                                            <tr>
                                                <th scope="col" className={"text-center"}>ID</th>
                                                <th scope="col">Merchant Username</th>
                                                <th scope="col">Merchant Email</th>
                                                <th scope="col">Requested Amount</th>
                                                <th scope="col">Requested Currency</th>
                                                <th scope="col">Requested Method</th>
                                                <th scope="col">Settlement Creation Date</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {loading && (
                                                <tr>
                                                    <td className="text-center p-4" colSpan={7}><Spinner/></td>
                                                </tr>
                                            )}
                                            {list && list.length > 0 && canViewPendingSettlements && list.map((transaction: PendingSettlement) => (
                                                <tr key={transaction.settlementRequestId} className={"fw-bold"}>
                                                    <td className={"text-center"}><span
                                                        className="fw-medium">{transaction.settlementRequestId}</span>
                                                    </td>
                                                    <td>{transaction.settlementRequestMerchantUsername}</td>
                                                    <td>{transaction.settlementRequestMerchantEmail}</td>
                                                    <td>{transaction.settlementRequestAmount}</td>
                                                    <td>{transaction.settlementRequestCurrency}</td>
                                                    <td>{transaction.settlementRequestMethod.toUpperCase()}</td>
                                                    <td>{transaction.settlementRequestCreationDate}</td>
                                                </tr>
                                            ))}
                                            {!loading && list.length === 0 && canViewPendingSettlements && (
                                                <tr>
                                                    <td className="text-center p-4" colSpan={13}>No Transactions Found
                                                    </td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </table>
                                        {list && list.length > 0 && canViewPendingSettlements && <div className="d-flex justify-content-center mt-3">
                                            <Pagination>
                                                <PaginationItem disabled={currentPage === 1}>
                                                    <PaginationLink previous
                                                                    onClick={() => handlePageChange(currentPage - 1)}/>
                                                </PaginationItem>
                                                {[...Array(lastPage)].map((_, i) => (
                                                    <PaginationItem key={i + 1} active={currentPage === i + 1}>
                                                        <PaginationLink onClick={() => handlePageChange(i + 1)}>
                                                            {i + 1}
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                ))}
                                                <PaginationItem disabled={currentPage === lastPage}>
                                                    <PaginationLink next
                                                                    onClick={() => handlePageChange(currentPage + 1)}/>
                                                </PaginationItem>
                                            </Pagination>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default ListPendingSettlements;