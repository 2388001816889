import React, { useEffect, useState } from "react";
import getApi from "../../../apis/get.api";
import {handleError, handleSuccess} from "../../../helpers/commonFunctions";
import {Label, Pagination, PaginationItem, PaginationLink, Spinner} from "reactstrap";

interface Props {
    resellerId: string | undefined;
    opened : boolean
}

const ResellerVouchersList = ({ resellerId ,opened}: Props) => {
    const [list, setList] = useState<any>([]);
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");
    const [voucherCode, setVoucherCode] = useState<string>("");
    const [voucherAmount, setVoucherAmount] = useState<string>("");
    const [loading,setLoading] = useState<boolean>(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);

    useEffect(() => {
        if (resellerId && opened) {
            getList();
        }
    }, [resellerId,opened]);

    const getList = (queryParam = '') => {
        setLoading(true)
        getApi(`/merchant/reseller/vouchers/${resellerId}?${queryParam}`)
        .then((response) => {
            const data = response.data.data.data;
            setList(data);
            setCurrentPage(response.data.data.current_page);
            setLastPage(response.data.data.last_page);
        })
        .catch((error) => {
            handleError(error);
        }).finally(() => {
            setLoading(false)
        })
    };

    const filterByData = (page ?: any) => {
        let query = '';

        if (startDate || endDate) {
            // Get today's date
            const today = new Date();
            const formattedToday = today.toISOString().split("T")[0]; // Format as YYYY-MM-DD

            // Calculate the date 3 months ago
            const threeMonthsAgo = new Date();
            threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
            const formattedThreeMonthsAgo = threeMonthsAgo.toISOString().split("T")[0]; // Format as YYYY-MM-DD

            // Use provided dates or defaults
            const start = startDate || formattedThreeMonthsAgo;
            const end = endDate || formattedToday;
            query = `&start=${start}&end=${end}`
        }

        if(voucherCode) {
            let append = '';
            if(query){
                append = '&'
            }
            query = append + `code=` + voucherCode
        }

        if(voucherAmount) {
            let append = '';
            if(query){
                append = '&'
            }
            query = append + `amount=` + voucherAmount
        }

        if(page) {
            let append = '';
            if(query){
                append = '&'
            }
            query = append + `page=` + page
        }
        getList(query)
    };

    const clearFilter = () => {
        setVoucherAmount('')
        setStartDate('')
        setEndDate('')
        setVoucherCode('')
        getList()
    }

    const exportResult = () => {
        let query = '';

        if (startDate || endDate) {
            // Get today's date
            const today = new Date();
            const formattedToday = today.toISOString().split("T")[0]; // Format as YYYY-MM-DD

            // Calculate the date 3 months ago
            const threeMonthsAgo = new Date();
            threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
            const formattedThreeMonthsAgo = threeMonthsAgo.toISOString().split("T")[0]; // Format as YYYY-MM-DD

            // Use provided dates or defaults
            const start = startDate || formattedThreeMonthsAgo;
            const end = endDate || formattedToday;
            query = `start=${start}&end=${end}`
        }

        if(voucherCode) {
            let append = '';
            if(query){
                append = '&';
            }
            query = append + `code=` + voucherCode
        }

        if(voucherAmount) {
            let append = '';
            if(query){
                append = '&';
            }
            query = append + `amount=` + voucherAmount
        }

        exportResultsList(query)
    };

    const exportResultsList = (queryParam = '') => {
        getApi(`/merchant/reseller/vouchers/export/${resellerId}?${queryParam}`)
        .then((response) => {
            handleSuccess(response)
        }).catch((error) => {
            handleError(error);
        })
    };

    const handlePageChange = (page : number) => {
        if (page !== currentPage) {
            setCurrentPage(page);
            filterByData(page)
        }
    };

    return (
        <div>
            {/* Date Filter Inputs */}
            <div className="d-flex gap-4">
                <div>
                    <Label>Voucher Code</Label>
                    <input
                        type="text"
                        value={voucherCode}
                        onChange={(e) => setVoucherCode(e.target.value)}
                        className="form-control"
                        placeholder={"Voucher Code"}
                    />
                </div>
                <div>
                    <Label>Voucher Amount</Label>
                    <input
                        type="text"
                        value={voucherAmount}
                        onChange={(e) => setVoucherAmount(e.target.value)}
                        className="form-control"
                        placeholder={"Voucher Amount"}
                    />
                </div>
                <div>
                    <Label>Date From</Label>
                    <input
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        className="form-control"
                    />
                </div>
                <div>
                    <Label>Date To</Label>
                    <input
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        className="form-control"
                        placeholder={"Date to"}
                    />
                </div>
                <div className={"d-flex flex-column"}>
                    <Label>Clear Filter</Label>
                    <button className="btn btn-danger" onClick={clearFilter}>
                        Clear Filter
                    </button>
                </div>
                <div className={"d-flex flex-column"}>
                    <Label>Apply Filter</Label>
                    <button className="btn btn-primary" onClick={() => {  filterByData() }}>
                        Apply Filter
                    </button>
                </div>
                <div className={"d-flex flex-column"}>
                    <Label>Export Results</Label>
                    <button className="btn btn-success" onClick={exportResult}>
                        Export Results To CSV
                    </button>
                </div>
            </div>
            <div className="table-responsive table-card mt-3 fw-bold">
                <table className="table text-center align-middle table-nowrap table-striped-columns mb-0">
                    <thead className="table-light">
                    <tr>
                        <th scope="col">Voucher ID</th>
                        <th scope="col">Voucher Code</th>
                        <th scope="col">Voucher Order ID</th>
                        <th scope="col">Voucher Amount</th>
                        <th scope="col">Voucher Currency</th>
                        <th scope="col">ClvrPay Voucher?</th>
                        <th scope="col">Voucher Status</th>
                        <th scope="col">Voucher Redeemed?</th>
                        <th scope="col">Voucher Redeem Date</th>
                        <th scope="col">Voucher Expiry Date</th>
                    </tr>
                    </thead>
                    <tbody>
                    {loading && <tr><td colSpan={11}><Spinner/></td></tr>}
                    {!loading && !list.length && (
                        <tr>
                            <td colSpan={11}>No Results</td>
                        </tr>
                    )}
                    {!loading && list.map((entity: any, index: number) => (
                        <tr key={index}>
                            <td>{entity.voucherId}</td>
                            <td>{entity.voucherCode}</td>
                            <td>{entity.voucherOrderId}</td>
                            <td>{entity.voucherAmount}</td>
                            <td>{entity.voucherCurrency}</td>
                            <td>{entity.voucherIsClvrPay ? 'Yes' : 'No'}</td>
                            <td>{entity.voucherStatus.toUpperCase()}</td>
                            <td>{entity.voucherRedeemedDate ? 'Yes' : 'No'}</td>
                            <td>{entity.voucherRedeemedDate ?? 'N/A'}</td>
                            <td>{entity.voucherExpiryDate}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <div className={"mt-5 text-center align-items-center justify-content-center"}>
                <Pagination>
                    {/* Previous Button */}
                    <PaginationItem disabled={currentPage === 1}>
                        <PaginationLink previous
                                        onClick={() => handlePageChange(currentPage - 1)}/>
                    </PaginationItem>

                    {/* Pagination Numbers */}
                    {[...Array(lastPage)].map((_, i) => {
                        // Display first two pages, last two pages, and pages near the current page
                        const pageNumber = i + 1;
                        const isNearCurrentPage = Math.abs(currentPage - pageNumber) <= 2;
                        const isFirstOrLastPage = pageNumber === 1 || pageNumber === lastPage;

                        if (isNearCurrentPage || isFirstOrLastPage) {
                            return (
                                <PaginationItem key={pageNumber}
                                                active={currentPage === pageNumber}>
                                    <PaginationLink
                                        onClick={() => handlePageChange(pageNumber)}>
                                        {pageNumber}
                                    </PaginationLink>
                                </PaginationItem>
                            );
                        }
                        // Ellipsis for skipped pages
                        if (pageNumber === 2 && currentPage > 4) {
                            return <PaginationItem key="ellipsis1"
                                                   disabled><PaginationLink>...</PaginationLink></PaginationItem>;
                        }
                        if (pageNumber === lastPage - 1 && currentPage < lastPage - 3) {
                            return <PaginationItem key="ellipsis2"
                                                   disabled><PaginationLink>...</PaginationLink></PaginationItem>;
                        }

                        return null; // Don't render other page numbers
                    })}

                    {/* Next Button */}
                    <PaginationItem disabled={currentPage === lastPage}>
                        <PaginationLink next onClick={() => handlePageChange(currentPage + 1)}/>
                    </PaginationItem>
                </Pagination>
            </div>
        </div>
    );
};

export default ResellerVouchersList;
