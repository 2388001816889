import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Card, CardBody, CardHeader, Dropdown, DropdownMenu, DropdownToggle, Form, ListGroup, ListGroupItem, Offcanvas } from 'reactstrap';

//import images
import logoSm from "../assets/images/logo-sm.png";
import logoDark from "../assets/images/logo-dark.png";
import logoLight from "../assets/images/logo-light.png";

//import Components
import SearchOption from '../Components/Common/SearchOption';
import LanguageDropdown from '../Components/Common/LanguageDropdown';
import WebAppsDropdown from '../Components/Common/WebAppsDropdown';
import MyCartDropdown from '../Components/Common/MyCartDropdown';
import FullScreenDropdown from '../Components/Common/FullScreenDropdown';
import NotificationDropdown from '../Components/Common/NotificationDropdown';
import ProfileDropdown from '../Components/Common/ProfileDropdown';
import LightDark from '../Components/Common/LightDark';

import { changeSidebarVisibility } from '../slices/thunks';
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from 'reselect';
import { isMobileDevice } from 'helpers/commonFunctions';
import SimpleBar from 'simplebar-react';
import Currency from 'Components/Common/Currency';

const Header = ({ onChangeLayoutMode, layoutModeType, headerClass }:any) => {
    const dispatch :any= useDispatch();
    const [open, setOpen] = useState<boolean>(false);

    const selectDashboardData = createSelector(
        (state:any) => state.Layout.sidebarVisibilitytype,
        (sidebarVisibilitytype) => sidebarVisibilitytype
      );
    // Inside your component
    const sidebarVisibilitytype = useSelector(selectDashboardData);

    const [search, setSearch] = useState<boolean>(false);
    const toogleSearch = () => {
        setSearch(!search);
    };

    const items =  [
        {
            id: "home",
            label: "Home",
            link: "/",
            parentId: "home",
        },
        {
            id: "dashboard",
            label: "Dashboard",
            link: "/dashboard",
            parentId: "dashboard",
        },
        {
            id: "transactions",
            label: "Account Settlements",
            link: "/wallet/settlements",
            parentId: "wallet",
        },
        {
            id: "transactions",
            label: "Account Statements",
            link: "/wallet/statements",
            parentId: "wallet",
        },
        {
            id: "transactions",
            label: "Pending Settlements",
            link: "/wallet/settlements/pending",
            parentId: "wallet",
        },
        {
            id: "vouchers",
            label: "Vouchers List",
            link: "/vouchers/list",
            parentId: "vouchers",
        },
        {
            id: "resellers",
            label: "My Resellers List",
            link: "/resellers/list",
            parentId: "resellers",
        },
        {
            id: "resellers",
            label: "Fund Reseller Wallet",
            link: "/resellers/wallet/fund",
            parentId: "resellers",
        },
        {
            id: "permissions",
            label: "Permissions",
            link: "/permissions",
            parentId: "permissions",
        },
        {
            id: "account",
            label: "Account Information",
            link: "/account/profile",
            parentId: "account",
        },
    ];

    const toogleMenuBtn = () => {
        var windowSize = document.documentElement.clientWidth;
        const humberIcon = document.querySelector(".hamburger-icon") as HTMLElement;
        dispatch(changeSidebarVisibility("show"));

        if (windowSize > 767)
        humberIcon.classList.toggle('open');


        //For collapse horizontal menu
        if (document.documentElement.getAttribute('data-layout') === "horizontal") {
            document.body.classList.contains("menu") ? document.body.classList.remove("menu") : document.body.classList.add("menu");
        }

        //For collapse vertical and semibox menu
        if (sidebarVisibilitytype === "show" && (document.documentElement.getAttribute('data-layout') === "vertical" || document.documentElement.getAttribute('data-layout') === "semibox")) {
            if (windowSize < 1025 && windowSize > 767) {
                document.body.classList.remove('vertical-sidebar-enable');
                (document.documentElement.getAttribute('data-sidebar-size') === 'sm') ? document.documentElement.setAttribute('data-sidebar-size', '') : document.documentElement.setAttribute('data-sidebar-size', 'sm');
            } else if (windowSize > 1025) {
                document.body.classList.remove('vertical-sidebar-enable');
                (document.documentElement.getAttribute('data-sidebar-size') === 'lg') ? document.documentElement.setAttribute('data-sidebar-size', 'sm') : document.documentElement.setAttribute('data-sidebar-size', 'lg');
            } else if (windowSize <= 767) {
                document.body.classList.add('vertical-sidebar-enable');
                document.documentElement.setAttribute('data-sidebar-size', 'lg');
            }
        }


        //Two column menu
        if (document.documentElement.getAttribute('data-layout') === "twocolumn") {
            document.body.classList.contains('twocolumn-panel') ? document.body.classList.remove('twocolumn-panel') : document.body.classList.add('twocolumn-panel');
        }
    };

    const openCanvas = () => {
        if(isMobileDevice()){
            setOpen(!open);
        }else{
            toogleMenuBtn()
        }
    };

    const navigate = useNavigate();

    const handleRedirect = (link : string) => {
        setOpen(false); 
        setTimeout(() => {
            navigate(link);
        }, 300);
    };

    return (
        <React.Fragment>
            <header id="page-topbar" className={headerClass}>
                <div className="layout-width">
                    <div className="navbar-header">
                        <div className="d-flex">

                            <div className="navbar-brand-box horizontal-logo">
                                <Link to="/" className="logo logo-dark">
                                    <span className="logo-sm">
                                        <img src={logoSm} alt="" height="22" />
                                    </span>
                                    <span className="logo-lg">
                                        <img src={logoDark} alt="" height="17" />
                                    </span>
                                </Link>

                                <Link to="/" className="logo logo-light">
                                    <span className="logo-sm">
                                        <img src={logoSm} alt="" height="22" />
                                    </span>
                                    <span className="logo-lg">
                                        <img src={logoLight} alt="" height="17" />
                                    </span>
                                </Link>
                            </div>

                            <button
                                onClick={openCanvas}
                                type="button"
                                className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                                id="topnav-hamburger-icon">
                                <span className="hamburger-icon">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                            </button>


                            {/*<SearchOption />*/}
                        </div>

                        <div className="d-flex align-items-center">
                            <Currency />
                            {/* <FullScreenDropdown /> */}
                            <LightDark
                                layoutMode={layoutModeType}
                                onChangeLayoutMode={onChangeLayoutMode}
                            />
                            <ProfileDropdown />
                        </div>
                    </div>
                </div>
            </header>
            <Offcanvas fade isOpen={open} toggle={openCanvas} id="offcanvasExample">
                <SimpleBar style={{ height: "100vh" }}>
                    <Card>
                        <CardHeader>
                            Welcome To Your Dashboard
                        </CardHeader>
                        <CardBody>
                            <ListGroup>
                                {items.map((item, index) => (
                                    <ListGroupItem key={index} onClick={() => handleRedirect(item.link)}>
                                        <i className="ri ri-briefcase-2-line align-middle lh-1 me-2"></i>
                                        {item.label}
                                    </ListGroupItem>
                                ))}
                            </ListGroup>
                        </CardBody>
                    </Card>
                </SimpleBar>
            </Offcanvas>
        </React.Fragment>
    );
};

export default Header;