import { toast } from "react-toastify"

export const handleError = (error : any) => {
    let message = 'Something went wrong while processing your request, please try again'
    if(error.response && error.response.data && error.response.data.message){
        message = error.response.data.message
    }
    toast.error(message)
}

export const handleSuccess = (response : any) => {
    let message = 'Something went wrong while processing your request, please try again'
    if(response && response.data && response.data.message){
        message = response.data.message
    }
    toast.success(message)
}

export const checkPermission = (permissionName : string) => {
    const permissions = JSON.parse(localStorage.getItem('merchant_permissions') || '[]');
    return permissions.includes(permissionName);
}

export const isMobileDevice = () => {
    if (typeof window !== 'undefined') {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            window.navigator.userAgent || ""
        );
    }
    return false; // Return false if window is not defined (e.g., server-side rendering)
}


export const getSystemCurrency = () => {
    if(localStorage.getItem('currency')) {
        return localStorage.getItem('currency')
    }
    return 'USD';
}