import React, { useEffect, useState } from 'react';
import {
    Container,
    Spinner,
    Pagination,
    PaginationItem,
    PaginationLink, ModalHeader, Modal, ModalBody,
} from "reactstrap";
import { toast } from "react-toastify";
import getApi from "../../apis/get.api";
import {Settlement} from "../../Interfaces/Interface";
import postApi from "../../apis/post.api";
import Select from "react-select";
import { checkPermission, getSystemCurrency, handleError, handleSuccess, isMobileDevice } from 'helpers/commonFunctions';

const ListSettlements = () => {
    document.title = "Wallet | Account Settlements";
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [showInformation,setShowInformation] = useState<boolean>(false);
    const [hasSettleError,setHasSettleError] = useState<boolean>(false);
    const [amount,setAmount] = useState<any>('')
    const [balance,setBalance] = useState<number>(0)
    const [madBalance,setMadBalance] = useState<number>(0)
    const [method,setMethod] = useState<number>(1)
    const [showSettleRequestModel, setShowSettleRequestModel] = useState(false);
    const [disabledRequestBtn, setDisabledRequestBtn] = useState(true);
    const [currency,setCurrency] = useState('USD');
    const canViewPage = checkPermission("/wallet/settlements");
    const systemCurrency = getSystemCurrency();
    const methods = [
        {
            id : 1,
            label : "Bank",
            value : 1
        },
        {
            id : 2,
            label : "Crypto",
            value : 2
        },
        {
            id : 3,
            label : "Cash",
            value : 3
        }
    ]

    const fetch = (page : number) => {
        setLoading(true);
        let url = `/merchant/wallet/settlements`;
        if (page) {
            url += `?page=${page}`;
        }
        getApi(url)
            .then((response) => {
                const data = response.data.data;
                setList(data.data);
                setCurrentPage(data.current_page);
                setLastPage(data.last_page);
            })
            .catch((error) => {
                handleError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if(canViewPage){
            getApi('/merchant/dashboard').then((response) => {
                setBalance(response.data.data.balance)
                setMadBalance(response.data.data.balanceMad)
            }).catch((error) => {
                handleError(error);
            })
        }
    }, [canViewPage]);

    useEffect(() => {
        if(canViewPage){
            fetch(currentPage);
        }else{
            setLoading(false)
        }
    }, [currentPage,canViewPage]);

    const handlePageChange = (page : number) => {
        if (page !== currentPage) {
            setCurrentPage(page);
        }
    };

    function tog_center() {
        setShowInformation(!showInformation);
    }

    const handleShowApprovePopup = () => {
        setShowSettleRequestModel(!showSettleRequestModel);
    }

    const handleAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const digitsOnly = value.replace(/\D/g, ''); // Only allow digits
        const numericAmount = digitsOnly ? parseFloat(digitsOnly) : 0;
        setAmount(digitsOnly);
        if (!digitsOnly) {
            setHasSettleError(false);
            setDisabledRequestBtn(true);
        }else if (numericAmount <= balance) {
            setHasSettleError(false);
            setDisabledRequestBtn(false);
        }else {
            setHasSettleError(true);
            setDisabledRequestBtn(true);
        }
    };

    const requestSettle = () => {
        postApi('/merchant/wallet/settlements/request',{amount : amount,method : method,currency : currency}).then(response => {
            handleSuccess(response);
            setDisabledRequestBtn(true)
            setShowSettleRequestModel(false)
        }).catch(error => {
            handleError(error)
        })
    }

    const changeMethod = (settleMethod : any) => {
        setMethod(settleMethod.value)
    }

    const changeCurrency = (event : any) => {
        setCurrency(event.target.value)
        if (event.target.value === 'USD' && parseFloat(amount) <= balance) {
            setHasSettleError(false);
            setDisabledRequestBtn(false);
        }else if (event.target.value === 'MAD' && parseFloat(amount) <= madBalance) {
            setHasSettleError(false);
            setDisabledRequestBtn(false);
        }else {
            setHasSettleError(true);
            setDisabledRequestBtn(true);
        }
    }

    return (
        <div className="page-content">
            <Container fluid={true}>
                {!canViewPage && <div className="display-5">You don't have permission to view this page</div>}
                {canViewPage && <div className="rounded-3">
                    <h1 className="display-5 fw-bold">Account Settlements</h1>
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header d-flex justify-content-between fs-5">
                                    {!isMobileDevice() && <span>In this page you will find all settlements transactions that has been made on your account</span>}
                                    <span><button className="btn btn-sm btn-primary" onClick={handleShowApprovePopup}>  Request Settlement  </button></span>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive table-card">
                                        <table
                                            className="table text-center align-middle table-nowrap table-striped-columns mb-0">
                                            <thead className="table-light">
                                            <tr>
                                                <th scope="col" className={"text-center"}>ID</th>
                                                <th scope="col">Merchant Username</th>
                                                <th scope="col">Settlement Request ID</th>
                                                <th scope="col">Settlement Amount</th>
                                                <th scope="col">Settlement Currency</th>
                                                <th scope="col">Balance Before Settle</th>
                                                <th scope="col">Balance After Settle</th>
                                                <th scope="col">Settlement Creation Date</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {loading && (
                                                <tr>
                                                    <td className="text-center p-4" colSpan={8}><Spinner/></td>
                                                </tr>
                                            )}
                                            {list && list.length > 0 && canViewPage &&  list.map((transaction: Settlement) => (
                                                <tr key={transaction.settlementId} className={"fw-bold"}>
                                                    <td className={"text-center"}><span
                                                        className="fw-medium">{transaction.settlementId}</span>
                                                    </td>
                                                    <td>{transaction.settlementMerchantUsername}</td>
                                                    <td>{transaction.settlementRequestId}</td>
                                                    <td>{transaction.settlementAmount}</td>
                                                    <td>{transaction.settlementCurrency}</td>
                                                    <td>{transaction.settlementAmountBefore}</td>
                                                    <td>{transaction.settlementAmountAfter}</td>
                                                    <td>{transaction.settlementCreationDate}</td>
                                                </tr>
                                            ))}
                                            {!loading && list.length === 0 && canViewPage && (
                                                <tr>
                                                    <td className="text-center p-4" colSpan={8}>No Transactions Found
                                                    </td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </table>
                                        <div className="d-flex justify-content-center mt-3">
                                            <Pagination>
                                                <PaginationItem disabled={currentPage === 1}>
                                                    <PaginationLink previous
                                                                    onClick={() => handlePageChange(currentPage - 1)}/>
                                                </PaginationItem>
                                                {[...Array(lastPage)].map((_, i) => (
                                                    <PaginationItem key={i + 1} active={currentPage === i + 1}>
                                                        <PaginationLink onClick={() => handlePageChange(i + 1)}>
                                                            {i + 1}
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                ))}
                                                <PaginationItem disabled={currentPage === lastPage}>
                                                    <PaginationLink next
                                                                    onClick={() => handlePageChange(currentPage + 1)}/>
                                                </PaginationItem>
                                            </Pagination>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
                <Modal isOpen={showSettleRequestModel} size={'md'} toggle={handleShowApprovePopup} centered>
                    <ModalHeader className="border-4 pb-3">
                        Request Settle
                    </ModalHeader>
                    <ModalBody className="">
                        <div className="g-3 row">
                            <div className="col-12">
                                <label className="form-label form-label">
                                    Available {systemCurrency} Balance To Settle
                                </label>
                                <input
                                    name="currenctBalance"
                                    id="currenctBalance"
                                    type="text"
                                    className="form-control"
                                    aria-invalid="false"
                                    placeholder="100"
                                    disabled={true}
                                    readOnly={true}
                                    value={balance}
                                />
                            </div>
                            <div className="col-12">
                                <label className="form-label form-label">
                                    Currency
                                </label>
                                <select className='form-control' onChange={changeCurrency} disabled={true}>
                                    <option value={systemCurrency ?? 'USD'} selected>{systemCurrency}</option>
                                </select>
                            </div>
                            <div className="col-12">
                                <label className="form-label form-label">
                                    Requested Settle Amount
                                </label>
                                <input
                                    name="settlementAmount"
                                    id="settlementAmount"
                                    type="text"
                                    className="form-control"
                                    aria-invalid="false"
                                    placeholder="100"
                                    value={amount}
                                    onChange={handleAmount}
                                />
                            </div>
                            <div className={"col-12"}>
                                <label className="form-label form-label">
                                    Settle Method
                                </label>
                                <Select
                                    options={methods}
                                    id="choices-single-default"
                                    className="js-example-basic-single mb-0"
                                    name="method"
                                    onChange={changeMethod}
                                    defaultValue={methods[0]}
                                />
                            </div>
                            {hasSettleError && <div className={"col-12"}>
                                <div className="alert alert-danger">
                                Requested settle amount is larger than the current available balance
                                </div>
                            </div>}
                            <div className="col-12">
                                <button className={'btn btn-primary w-100'} onClick={requestSettle} disabled={disabledRequestBtn}>Send Request </button>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </Container>
        </div>
    );
};

export default ListSettlements;