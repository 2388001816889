import { useEffect, useState } from 'react';
import {
    Container,
    Spinner,
    Pagination,
    PaginationItem,
    PaginationLink, Col, Label, Input, Row,
} from "reactstrap";
import getApi from "../../apis/get.api";
import CreateReseller from "./components/CreateReseller";
import { checkPermission, getSystemCurrency, handleError, isMobileDevice } from 'helpers/commonFunctions';
import {Link} from "react-router-dom";

const Resellers = () => {
    document.title = "Merchant | Resellers List";
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [resellerEmail, setResellerEmail] = useState('');
    const [open, setOpen] = useState(false);
    const canViewPage =  checkPermission('/resellers/list');

    const fetch = (page : number, email ?: string) => {
        setLoading(true);
        let url = `/merchant/resellers/list?page=${page}&per_page=20`;
        if (email) {
            url += `&email=${email}`;
        }
        getApi(url)
            .then((response) => {
                const data = response.data.data;
                setList(data.data);
                setCurrentPage(data.current_page);
                setLastPage(data.last_page);
            })
            .catch((error) => {
                handleError(error)
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if(canViewPage){
            fetch(currentPage, resellerEmail);
        }else{
            setLoading(false)
        }
    }, [currentPage, resellerEmail,canViewPage]);

    const handlePageChange = (page : number) => {
        if (page !== currentPage) {
            setCurrentPage(page);
        }
    };

    const handleFilterChange = (e : any) => {
        setResellerEmail(e.target.value);
    };

    const handleFilterSubmit = (e : any) => {
        e.preventDefault();
        setCurrentPage(1);
        fetch(1, resellerEmail);
    };

    const getStatusLabel = (status : string) => {
        let label = 'Approved';
        switch (status) {
            case 'b':
                label = 'Blocked';
                break;
            case 'p':
                label = 'Pending';
                break;
            case 'r':
                label = 'Rejected';
                break;
        }
        return label;
    }

    const handleCreateModal = () => {
        setOpen(!open)
    }

    const forceFetch = () => {
        fetch(1);
    }

    return (
        <div className="page-content">
            <Container fluid={true}>
                <div className="rounded-3">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header fs-4 d-flex justify-content-between">
                                    {!isMobileDevice() && <div className={"fs-5"}>
                                        In this page you will find all resellers list that are assigned to you as a merchant
                                    </div>}
                                    <div>
                                        <button className={`btn btn-primary btn-sm ${isMobileDevice() ? 'w-100' : ''}`} onClick={handleCreateModal}>Create Reseller</button>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <Row className=" align-items-center mb-5">
                                        <Col lg={4} xs={12} >
                                            <Label className="visually-hidden" htmlFor="inlineFormInputGroupUsername">Username</Label>
                                            <div className="input-group">
                                                <div className="input-group-text">Reseller Email</div>
                                                <Input type="text" className="form-control" id="resellerEmail" value={resellerEmail} onChange={handleFilterChange} placeholder="Reseller Email" />
                                            </div>
                                        </Col>
                                        <Col lg={8} xs={12} className={`text-end ${isMobileDevice() ? 'mt-2' : ''}`}>
                                            <button type="submit" className="btn btn-primary" onClick={handleFilterSubmit}>Search</button>
                                        </Col>
                                    </Row>
                                    <div className="table-responsive table-card">
                                        <table className="table text-center align-middle table-nowrap table-striped-columns mb-0">
                                            <thead className="table-light">
                                            <tr>
                                                <th scope="col">Reseller Name</th>
                                                <th scope="col">Reseller Email</th>
                                                <th scope="col">Reseller Country</th>
                                                <th scope="col">Reseller Phone Number</th>
                                                <th scope="col">Reseller Trader Name | Website</th>
                                                <th scope="col">Reseller Status</th>
                                                <th scope="col">Reseller {getSystemCurrency()} Balance</th>
                                                <th scope="col">View</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {loading && (
                                                    <tr>
                                                        <td className="text-center p-4" colSpan={9}><Spinner /></td>
                                                    </tr>
                                                )}
                                                {list && list.length > 0 && canViewPage && list.map((reseller : any) => (
                                                    <tr key={reseller.resellerId} className={"fw-bold"}>
                                                        <td>{reseller.resellerUsername}</td>
                                                        <td>{reseller.resellerEmail}</td>
                                                        <td>{reseller.resellerCountryName}</td>
                                                        <td>{reseller.resellerPhoneNumber ?? 'Not Provided'}</td>
                                                        <td>{reseller.resellerWebsiteUrl ?? 'Not Provided'}</td>
                                                        <td>{getStatusLabel(reseller.resellerStatus)}</td>
                                                        <td>{reseller.resellerBalance ?? 0}</td>
                                                        <td>
                                                            <Link to={`/reseller/${reseller.resellerId}`} className={"btn btn-sm btn-primary"} >View</Link>
                                                        </td>

                                                    </tr>
                                                ))}
                                                {!loading && list.length === 0 && canViewPage && (
                                                    <tr>
                                                        <td className="text-center p-4" colSpan={9}>No Resellers Found</td>
                                                    </tr>
                                                )}
                                                {!loading && list.length === 0 && !canViewPage && (
                                                    <tr>
                                                        <td className="text-center p-4" colSpan={9}>You don't have permission to view resellers list</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                    {canViewPage && <div className="card-footer d-flex justify-content-center mt-4 border-top-0">
                                        <Pagination>
                                            <PaginationItem disabled={currentPage === 1}>
                                                <PaginationLink previous onClick={() => handlePageChange(currentPage - 1)} />
                                            </PaginationItem>
                                            {[...Array(lastPage)].map((_, i) => (
                                                <PaginationItem key={i + 1} active={currentPage === i + 1}>
                                                    <PaginationLink onClick={() => handlePageChange(i + 1)}>
                                                        {i + 1}
                                                    </PaginationLink>
                                                </PaginationItem>
                                            ))}
                                            <PaginationItem disabled={currentPage === lastPage}>
                                                <PaginationLink next onClick={() => handlePageChange(currentPage + 1)} />
                                            </PaginationItem>
                                        </Pagination>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {canViewPage && <CreateReseller showModal={open} closeModal={handleCreateModal} forceFetch={forceFetch}/>}
            </Container>
        </div>
    );
};

export default Resellers;