import { useEffect, useState } from 'react';
import {
    Container,
    Col,
    Row,
    Label,
    Input,
    Spinner, Alert

} from "reactstrap";
import getApi from "../../apis/get.api";
import { checkPermission, handleError } from 'helpers/commonFunctions';

const Profile = () => {
    document.title = "ClvrPay.com | Merchant Profile";
    const [profile, setProfile] = useState<any>(null);
    const [loading, setLoading] = useState(true);
    const canViewMerchantProfile = checkPermission("/account/profile");
    useEffect(() => {
        if(canViewMerchantProfile){
            setLoading(true);
            getApi('/merchant/account/profile').then((response) => {
                setLoading(false);
                const data = response.data.data
                if(data){
                    setProfile(data);
                }
            }).catch((error) => {
                handleError(error)
            })
        }else{
            setLoading(false);
        }
    }, [canViewMerchantProfile]);

    return (
        <div className="page-content">
            <Container fluid={true}>
                <div className="rounded-3">
                    <h1 className="display-5 fw-bold">Account Information</h1>
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header fs-4">
                                    In this page you will find all your account information
                                </div>
                                <div className="card-body">
                                    {canViewMerchantProfile && <Alert color="primary">
                                        <strong>To change any of your information, please contact your account manager</strong>
                                    </Alert>}
                                    {!loading && profile && canViewMerchantProfile && <Row>
                                        <Col sm={12} md={12} lg={3} className={"mt-3"}>
                                            <Label for="merchantName" className="form-label">Name</Label>
                                            <Input type="text" className="form-control" id="merchantName" disabled={true} readOnly={true} placeholder="Enter your merchant name" value={profile.merchantName}/>
                                        </Col>
                                        <Col sm={12} md={12} lg={3} className={"mt-3"}>
                                            <Label for="merchantEmail" className="form-label">Email</Label>
                                            <Input type="email" className="form-control" id="merchantEmail" disabled={true} readOnly={true} placeholder="Email" value={profile.merchantEmail}/>
                                        </Col>
                                        <Col sm={12} md={12} lg={3} className={"mt-3"}>
                                            <Label for="merchantCountry" className="form-label">Country</Label>
                                            <Input type="email" className="form-control" id="merchantCountry" disabled={true} readOnly={true} placeholder="Email" value={profile.merchantCountry}/>
                                        </Col>
                                        <Col sm={12} md={12} lg={3} className={"mt-3"}>
                                            <Label for="merchantWebsite" className="form-label">Website</Label>
                                            <Input type="text" className="form-control" id="merchantWebsite" disabled={true} readOnly={true} value={profile.merchantWebsite} placeholder="Enter your website"/>
                                        </Col>
                                        <Col sm={12} md={12} lg={3} className={"mt-3"}>
                                            <Label for="merchantCommission" className="form-label">Deducted Commission</Label>
                                            <Input type="text" className="form-control" id="merchantCommission" disabled={true} readOnly={true} value={'%' + parseFloat(profile.merchantCommission)}/>
                                        </Col>
                                        <Col sm={12} md={12} lg={3} className={"mt-3"}>
                                            <Label for="merchantPayoutCommission" className="form-label">Deducted Payout Commission</Label>
                                            <Input type="text" className="form-control" id="merchantPayoutCommission" disabled={true} readOnly={true} value={'%' + parseFloat(profile.merchantPayoutCommission)}/>
                                        </Col>
                                    </Row>}
                                    {loading && <Spinner/>}
                                    {!loading && !canViewMerchantProfile && <div className='text-center'>You don't have permission to view this page, please contact your account manager</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default Profile;