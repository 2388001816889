import { getSystemCurrency } from "helpers/commonFunctions";
import ReactApexChart from "react-apexcharts";

interface Prop {
    categories : any
    values : any
    dataColors : any
    horizontal : any
    distributed : any
    enableDataLabels : any
    title ?: any
}
const Bar = ({categories,values, dataColors, horizontal,distributed,enableDataLabels,title} :any) => {
    const getChartColorsArray = (colors : any) => {
        colors = JSON.parse(colors);
        return colors.map(function (value : any) {
            var newValue = value.replace(" ", "");
            if (newValue.indexOf(",") === -1) {
                var color = getComputedStyle(document.documentElement).getPropertyValue(newValue);
    
                if (color.indexOf("#") !== -1)
                    color = color.replace(" ", "");
                if (color) return color;
                else return newValue;
            } else {
                var val = value.split(',');
                if (val.length === 2) {
                    var rgbaColor = getComputedStyle(document.documentElement).getPropertyValue(val[0]);
                    rgbaColor = "rgba(" + rgbaColor + "," + val[1] + ")";
                    return rgbaColor;
                } else {
                    return newValue;
                }
            }
        });
    }

    var chartDatalabelsBarColors = dataColors ? getChartColorsArray(dataColors) : null;
    const series = [{
        data: values
    }];
    var options :any = {
        chart: {
            type: 'bar',
            height: 350,
            toolbar: {
                show: true,
            }
        },
        plotOptions: {
            bar: {
                barHeight: '100%',
                distributed: distributed,
                horizontal: horizontal,
                dataLabels: {
                    position: 'bottom'
                },
            }
        },
        dataLabels: {
            enabled: enableDataLabels,
            textAnchor: 'start',
            style: {
                colors: ['#fff']
            },
            formatter: function (val :any, opt :any) {
                // return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val + " " + getSystemCurrency();
                return val + " " + getSystemCurrency();
            },
            offsetX: 0,
            dropShadow: {
                enabled: true
            }
        },
        stroke: {
            width: 1,
            colors: ['#fff']
        },
        xaxis: {
            categories: categories
        },
        yaxis: {
            labels: {
                show: true
            },
            title: title ? {
                text: title,
                style: {
                    color: "#FF1654",
                },
            } : {}
        },
        tooltip: {
            theme: 'dark',
            x: {
                show: true
            },
            y: {
                title: {
                    formatter: function () {
                        return '';
                    }
                }
            }
        }
    };
    return <ReactApexChart dir="ltr"
        className="apex-charts"
        options={options}
        series={series}
        type="bar"
        height={350}
    />
};

export default Bar