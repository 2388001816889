import { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row, Spinner } from 'reactstrap';
import Section1 from "../../project-pages/dashboard/Section1";
import getApi from "../../apis/get.api";
import { checkPermission, getSystemCurrency, handleError } from 'helpers/commonFunctions';
import Bar from 'Components/charts/Bar';
import Area from 'Components/charts/Area';
import Mixed from 'Components/charts/Mixed';
import CountUp from 'react-countup';

const Dashboard = () => {
    document.title = "ClvrPay.com | Dashboard";
    const [balance, setBalance] = useState<number | undefined>();
    const [secondlyBalance, setSecondlyBalance] = useState<number>(0);
    const [noneClvrPay, setNoneClvrPay] = useState<number>(0);
    const [activeVouchers, setActiveVouchers] = useState<number>(0);
    const [clvrPay, setClvrPay] = useState<number>(0);
    const canViewPage = checkPermission("/dashboard");
    const [categories, setCategories] = useState<any>([]);
    const [values, setValues] = useState<any>([]);
    const [vouchersCategories, setVouchersCategories] = useState<any>([]);
    const [vouchersValues, setVouchersValues] = useState<any>([]);
    const [selectedFundWalletFilter, setSelectedFundWalletFilter] = useState('1y');
    const [selectedVoucherReport, setSelectedVouchersReport] = useState('1m');
    const [vouchersReportCategories, setVouchersReportCategories] = useState<any>([]);
    const [vouchersReportValues, setVouchersReportValues] = useState<any>([]);

    const [userName, setUserName] = useState("Admin");
    useEffect(() => {
        const authUSer : any = localStorage.getItem("merchant_authUser");
        if (authUSer) {
            const obj : any = JSON.parse(authUSer);
            setUserName(obj.username);
        }
    }, [userName]);

    useEffect(() => {
        if (!canViewPage) {
            window.location.href = '/403';
        }
        getApi('/merchant/dashboard').then((response) => {
            setBalance(response.data.data.balance);
            setSecondlyBalance(response.data.data.secondlyBalance);
            setClvrPay(response.data.data.clvrPayVouchersAmount)
            setNoneClvrPay(response.data.data.noneClvrPayVouchersAmount)
            setActiveVouchers(response.data.data.activeVouchers)
        }).catch((error) => {
            handleError(error);
        });

        getApi('/merchant/report/chart/resellers-wallet-fund').then((response) => {
            setCategories(response.data.data.resellers);
            setValues(response.data.data.amount);
        }).catch((error) => {
            handleError(error);
        });

        // Add your API call here if needed
        getApi(`/merchant/report/chart/merchant-redeemed-vouchers?groupBy=day`).then((response) => {
            console.log(response.data.data.categories)
            setVouchersCategories(response.data.data.categories);
            setVouchersValues(response.data.data.values);
        }).catch((error) => {
            handleError(error);
        });

        // Add your API call here if needed
        getApi(`/merchant/report/chart/vouchers-report`).then((response) => {
            setVouchersReportCategories(response.data.data.categories);
            setVouchersReportValues(response.data.data.values);
        }).catch((error) => {
            handleError(error);
        });
    }, [canViewPage]);

    const filterFundWallet = (dateRange: string) => {
        const endDate = new Date();
        let startDate = new Date();
    
        switch (dateRange) {
            case '1w': // 1 week
                startDate.setDate(endDate.getDate() - 7);
                break;
            case '1m': // 1 month
                startDate.setMonth(endDate.getMonth() - 1);
                break;
            case '6m': // 6 months
                startDate.setMonth(endDate.getMonth() - 6);
                break;
            case '1y': // 1 year
                startDate.setFullYear(endDate.getFullYear() - 1);
                break;
            default:
                return;
        }
    
        setSelectedFundWalletFilter(dateRange);
    
        // Format dates to yyyy-mm-dd
        const formatDate = (date: Date) => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        };
    
        const formattedStartDate = formatDate(startDate);
        const formattedEndDate = formatDate(endDate);
    
        console.log(`Start Date: ${formattedStartDate}`);
        console.log(`End Date: ${formattedEndDate}`);
    
        // Add your API call here if needed
        getApi(`/merchant/report/chart/resellers-wallet-fund?startDate=${formattedStartDate}&endDate=${formattedEndDate}`).then((response) => {
            setCategories(response.data.data.resellers);
            setValues(response.data.data.amount);
        }).catch((error) => {
            handleError(error);
        });
    };

    const filterVoucherReport = (dateRange: string,groupBy = 'day') => {
        const endDate = new Date();
        let startDate = new Date();
    
        switch (dateRange) {
            case '1w': // 1 week
                startDate.setDate(endDate.getDate() - 7);
                break;
            case '1m': // 1 month
                startDate.setMonth(endDate.getMonth() - 1);
                break;
            case '6m': // 6 months
                startDate.setMonth(endDate.getMonth() - 6);
                break;
            case '1y': // 1 year
                startDate.setFullYear(endDate.getFullYear() - 1);
                break;
            default:
                return;
        }
    
        setSelectedVouchersReport(dateRange);
    
        // Format dates to yyyy-mm-dd
        const formatDate = (date: Date) => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        };
    
        const formattedStartDate = formatDate(startDate);
        const formattedEndDate = formatDate(endDate);
    
        console.log(`Start Date: ${formattedStartDate}`);
        console.log(`End Date: ${formattedEndDate}`);
    
        // Add your API call here if needed
        getApi(`/merchant/report/chart/merchant-redeemed-vouchers?startDate=${formattedStartDate}&endDate=${formattedEndDate}&groupBy=${groupBy}`).then((response) => {
            setVouchersCategories(response.data.data.categories);
            setVouchersValues(response.data.data.values);
        }).catch((error) => {
            handleError(error);
        });
    };
    
    return (
        <div className="page-content">
            <Container fluid={true}>
                {!canViewPage && <div className="display-5">You don't have permission to view this page</div>}
                {canViewPage && <div className="rounded-3">
                    <Row className="mb-3 pb-1">
                        <Col xs={12}>
                            <div className="d-flex align-items-lg-center flex-lg-row flex-column">
                                <div className="flex-grow-1">
                                    <h4 className="fs-16 mb-1">Good Morning, {userName}!</h4>
                                    <p className="text-muted mb-0">Here's what's happening with your account.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {!balance && balance != 0 && <div className={"p-5 text-center"}><Spinner/></div>}
                    {balance && <div className='row'>
                        <div className="col-xl-12">
                            <div className="card crm-widget">
                                <div className="card-body p-0">
                                    <div className="row row-cols-xxl-5 row-cols-md-3 row-cols-1 g-0">
                                        <div className="col" >
                                            <div className="py-4 px-3">
                                                <h5 className="text-muted text-uppercase fs-13">Balance To Withdrawal<i
                                                    className={" fs-18 float-end align-middle"}></i></h5>
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0">
                                                        <i className={"display-6 text-muted"}></i>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h2 className="mb-0">
                                                            <span className="counter-value" data-target="197">
                                                            <CountUp
                                                                start={0}
                                                                prefix={"$"}
                                                                suffix={""}
                                                                separator={","}
                                                                end={balance ?? 0}
                                                                decimals={2}
                                                                duration={2}
                                                            />
                                                            </span>
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col" >
                                            <div className="py-4 px-3 ms-3">
                                                <h5 className="text-muted text-uppercase fs-13">Pre-loaded Balance<i
                                                    className={" fs-18 float-end align-middle"}></i></h5>
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0">
                                                        <i className={"display-6 text-muted"}></i>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h2 className="mb-0">
                                                            <span className="counter-value" data-target="197">
                                                            <CountUp
                                                                start={0}
                                                                prefix={"$"}
                                                                suffix={""}
                                                                separator={","}
                                                                end={secondlyBalance ?? 0}
                                                                decimals={2}
                                                                duration={4}
                                                            />
                                                            </span>
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col" >
                                            <div className="py-4 px-3 ms-3">
                                                <h5 className="text-muted text-uppercase fs-13">Redeemed ClvrPay Vouchers<i
                                                    className={" fs-18 float-end align-middle"}></i></h5>
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0">
                                                        <i className={"display-6 text-muted"}></i>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h2 className="mb-0">
                                                            <span className="counter-value" data-target="197">
                                                            <CountUp
                                                                start={0}
                                                                prefix={"$"}
                                                                suffix={""}
                                                                separator={","}
                                                                end={clvrPay ?? 0}
                                                                decimals={2}
                                                                duration={4}
                                                            />
                                                            </span>
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col" >
                                            <div className="py-4 px-3 ms-3">
                                                <h5 className="text-muted text-uppercase fs-13">Redeemed {userName} Vouchers<i
                                                    className={" fs-18 float-end align-middle"}></i></h5>
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0">
                                                        <i className={"display-6 text-muted"}></i>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h2 className="mb-0">
                                                            <span className="counter-value" data-target="197">
                                                            <CountUp
                                                                start={0}
                                                                prefix={"$"}
                                                                suffix={""}
                                                                separator={","}
                                                                end={noneClvrPay ?? 0}
                                                                decimals={2}
                                                                duration={4}
                                                            />
                                                            </span>
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col" >
                                            <div className="py-4 px-3 ms-3">
                                                <h5 className="text-muted text-uppercase fs-13">Active {userName} Vouchers<i
                                                    className={" fs-18 float-end align-middle"}></i></h5>
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0">
                                                        <i className={"display-6 text-muted"}></i>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h2 className="mb-0">
                                                            <span className="counter-value" data-target="197">
                                                            <CountUp
                                                                start={0}
                                                                prefix={"$"}
                                                                suffix={""}
                                                                separator={","}
                                                                end={activeVouchers ?? 0}
                                                                decimals={2}
                                                                duration={4}
                                                            />
                                                            </span>
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                    <Row>
                        {vouchersReportCategories.length > 0 && <Col lg={6}>
                            <Card>
                                <div className="border-1 align-items-center d-flex card-header">
                                    <h4 className="card-title mb-0 flex-grow-1">Vouchers Totals Report</h4>
                                    <div className="d-flex gap-1">
                                        {/* <button type="button" onClick={() => { filterFundWallet('1w'); }} className={`btn ${selectedFundWalletFilter === '1w' ? 'btn-primary' : 'btn-soft-secondary'} btn-sm shadow-none`}>1W</button>
                                        <button type="button" onClick={() => { filterFundWallet('1m'); }} className={`btn ${selectedFundWalletFilter === '1m' ? 'btn-primary' : 'btn-soft-secondary'} btn-sm shadow-none`}>1M</button>
                                        <button type="button" onClick={() => { filterFundWallet('6m'); }} className={`btn ${selectedFundWalletFilter === '6m' ? 'btn-primary' : 'btn-soft-secondary'} btn-sm shadow-none`}>6M</button>
                                        <button type="button" onClick={() => { filterFundWallet('1y'); }} className={`btn ${selectedFundWalletFilter === '1y' ? 'btn-primary' : 'btn-soft-secondary'} btn-sm shadow-none`}>1Y</button> */}
                                        <button type="button" disabled
                                                className={`btn btn-secondary btn-sm shadow-none`}>No Filter
                                        </button>
                                    </div>
                                </div>
                                <CardBody>
                                    <Mixed enableDataLabels={true} distributed={true} horizontal={true}
                                           categories={vouchersReportCategories} values={vouchersReportValues}
                                           dataColors='["--vz-primary", "--vz-secondary", "--vz-success", "--vz-info", "--vz-warning", "--vz-danger", "--vz-dark", "--vz-primary", "--vz-success", "--vz-secondary"]'/>
                                </CardBody>
                            </Card>
                        </Col>}
                        {categories.length > 0 && <Col lg={6}>
                            <Card>
                                <div className="border-1 align-items-center d-flex card-header">
                                    <h4 className="card-title mb-0 flex-grow-1">My Resellers Fund Chart</h4>
                                    <div className="d-flex gap-1">
                                        <button type="button" onClick={() => {
                                            filterFundWallet('1w');
                                        }}
                                                className={`btn ${selectedFundWalletFilter === '1w' ? 'btn-primary' : 'btn-soft-secondary'} btn-sm shadow-none`}>1W
                                        </button>
                                        <button type="button" onClick={() => {
                                            filterFundWallet('1m');
                                        }}
                                                className={`btn ${selectedFundWalletFilter === '1m' ? 'btn-primary' : 'btn-soft-secondary'} btn-sm shadow-none`}>1M
                                        </button>
                                        <button type="button" onClick={() => {
                                            filterFundWallet('6m');
                                        }}
                                                className={`btn ${selectedFundWalletFilter === '6m' ? 'btn-primary' : 'btn-soft-secondary'} btn-sm shadow-none`}>6M
                                        </button>
                                        <button type="button" onClick={() => {
                                            filterFundWallet('1y');
                                        }}
                                                className={`btn ${selectedFundWalletFilter === '1y' ? 'btn-primary' : 'btn-soft-secondary'} btn-sm shadow-none`}>1Y
                                        </button>
                                    </div>
                                </div>
                                <CardBody>
                                    <Bar enableDataLabels={true} distributed={true} horizontal={true}
                                         categories={categories} values={values}
                                         dataColors='["--vz-primary", "--vz-secondary", "--vz-success", "--vz-info", "--vz-warning", "--vz-danger", "--vz-dark", "--vz-primary", "--vz-success", "--vz-secondary"]'/>
                                </CardBody>
                            </Card>
                        </Col>}
                        {vouchersCategories.length > 0 && <Col lg={12}>
                            <Card>
                                <div className="border-1 align-items-center d-flex card-header">
                                    <h4 className="card-title mb-0 flex-grow-1">My Redeemed Vouchers chart</h4>
                                    <div className="d-flex gap-1">
                                        <button type="button" onClick={() => {
                                            filterVoucherReport('1w', 'day');
                                        }}
                                                className={`btn ${selectedVoucherReport === '1w' ? 'btn-primary' : 'btn-soft-secondary'} btn-sm shadow-none`}>1W
                                        </button>
                                        <button type="button" onClick={() => {
                                            filterVoucherReport('1m', 'day');
                                        }}
                                                className={`btn ${selectedVoucherReport === '1m' ? 'btn-primary' : 'btn-soft-secondary'} btn-sm shadow-none`}>1M
                                        </button>
                                        <button type="button" onClick={() => {
                                            filterVoucherReport('6m', 'month');
                                        }}
                                                className={`btn ${selectedVoucherReport === '6m' ? 'btn-primary' : 'btn-soft-secondary'} btn-sm shadow-none`}>6M
                                        </button>
                                        <button type="button" onClick={() => {
                                            filterVoucherReport('1y', 'month');
                                        }}
                                                className={`btn ${selectedVoucherReport === '1y' ? 'btn-primary' : 'btn-soft-secondary'} btn-sm shadow-none`}>1Y
                                        </button>
                                    </div>
                                </div>
                                <CardBody>
                                    <Bar enableDataLabels={false} distributed={false} horizontal={false}
                                         categories={vouchersCategories} values={vouchersValues} dataColors={null}/>
                                </CardBody>
                            </Card>
                        </Col>}
                    </Row>
                </div>}
            </Container>
        </div>
    );
};

export default Dashboard;
