import {Container } from 'reactstrap';
const Home = () => {
    document.title = "ClvrPay.com | Home";
    return (
        <div className="page-content">
            <Container fluid={true}>
                <p className="display-4">Welcome to ClvrPay Dashboard Center</p>  
                <p className="lead">
                    ClvrPay is a comprehensive payment processing platform designed to simplify financial transactions for businesses and merchants. With our user-friendly dashboard, you can easily manage account settlements, track transactions, and oversee your resellers. Enjoy secure and efficient payment solutions, empowering your business to thrive in a competitive market. Experience seamless financial management with ClvrPay today!
                </p>
                <h5 className="fw-semibold mt-4">Account Settlements</h5>
                <p className="text-muted">
                    In this page you will find all settlements transactions that has been made on your account
                </p>
                <h5 className="fw-semibold ">Account Statements</h5>
                <p className="text-muted">
                    In this page you will find all statements transactions that has been made on your account
                </p>
                <h5 className="fw-semibold ">Pending Settlements</h5>
                <p className="text-muted">
                    In this page you will find all settlements transactions that has been made on your account
                </p>
                <h5 className="fw-semibold ">Vouchers List</h5>
                <p className="text-muted">
                    In this page you will find all generated vouchers for your account, along with the status of each voucher
                </p>
                <h5 className="fw-semibold ">My Resellers List</h5>
                <p className="text-muted">
                    In this page you will find all resellers list that are assigned to you as a merchant
                </p>
                <h5 className="fw-semibold ">Create Reseller</h5>
                <p className="text-muted">
                    In this page you will be able to create a reseller in which will sell the merchant vouchers
                </p>
                <h5 className="fw-semibold ">Fund Reseller Wallet</h5>
                <p className="text-muted">
                    In this page you will be able to fund reseller wallet
                </p>
                <h5 className="fw-semibold ">Permissions</h5>
                <p className="text-muted">
                    In this page you can assign roles, Modify permissions, Create new accounts
                </p>
                <h5 className="fw-semibold ">Account Information</h5>
                <p className="text-muted">
                    In this page you will find all your account information
                </p>
            </Container>
        </div>
    );
};
export default Home;
