import {Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner} from "reactstrap";
import {useEffect, useState} from "react";
import reseller from "../Reseller";
import getApi from "../../../apis/get.api";
import {getSystemCurrency, handleError, handleSuccess} from "../../../helpers/commonFunctions";
import Select from "react-select/base";
import postApi from "../../../apis/post.api";

interface Props {
    resellerId: string | undefined;
    opened : boolean;
    profile : any;
    forceUpdate() : void
}

const ResellerDashboard = ({resellerId,opened,profile,forceUpdate} : Props) => {
    const [btnDisabled, setBtnDisabled] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [openedModel,setOpenedModel] = useState<boolean>(false)
    const [amount,setAmount] = useState<any>(null)

    const openDeductModel = () => {
        setOpenedModel(!openedModel)
    }

    const handleAmount = (event : any) => {
        var amountValue : any = parseFloat(event.target.value)
        if(event.target.value === ''){
            amountValue = '';
        }
        setAmount(amountValue);
        if(amountValue > profile.resellerBalance){
            setHasError(true)
            setBtnDisabled(true)
        }else{
            setHasError(false)
            setBtnDisabled(false)
        }
    }

    const deduct = () => {
        postApi('/merchant/reseller/wallet/deduct/' + resellerId,{amount : amount,resellerId : resellerId}).then(response => {
            handleSuccess(response)
            setBtnDisabled(true)
            setAmount(0)
            setHasError(false)
            setOpenedModel(false)
            forceUpdate()
        }).catch(error => {
            handleError(error)
        })
    }

    return <>
        {profile && <Row>
            <Col sm={12} md={12} lg={3} className={"mt-3"}>
                <Label for="resellerUsername" className="form-label">Name</Label>
                <Input type="text" className="form-control" id="resellerUsername" disabled={true} readOnly={true} placeholder="Enter your merchant name" value={profile.resellerUsername}/>
            </Col>
            <Col sm={12} md={12} lg={3} className={"mt-3"}>
                <Label for="resellerEmail" className="form-label">Email</Label>
                <Input type="email" className="form-control" id="resellerEmail" disabled={true} readOnly={true} placeholder="Email" value={profile.resellerEmail}/>
            </Col>
            <Col sm={12} md={12} lg={3} className={"mt-3"}>
                <Label for="resellerCountryName" className="form-label">Country</Label>
                <Input type="email" className="form-control" id="resellerCountryName" disabled={true} readOnly={true} placeholder="Email" value={profile.resellerCountryName}/>
            </Col>
            <Col sm={12} md={12} lg={3} className={"mt-3"}>
                <Label for="resellerWebsiteUrl" className="form-label">Website</Label>
                <Input type="text" className="form-control" id="resellerWebsiteUrl" disabled={true} readOnly={true} value={profile.resellerWebsiteUrl} placeholder="Enter your website"/>
            </Col>
            <Col sm={12} md={12} lg={3} className={"mt-3"}>
                <Label for="resellerPhoneNumber" className="form-label">Website</Label>
                <Input type="text" className="form-control" id="resellerPhoneNumber" disabled={true} readOnly={true} value={profile.resellerPhoneNumber} placeholder="Enter your website"/>
            </Col>
            <Col sm={12} md={12} lg={3} className={"mt-3"}>
                <Label for="resellerBalance" className="form-label">Current Balance In {getSystemCurrency()}</Label>
                <Input type="text" className="form-control" id="resellerBalance" disabled={true} readOnly={true} value={parseFloat(profile.resellerBalance)}/>
            </Col>
            <Col sm={12} md={12} lg={3} className={"mt-3 "}>
                <Label  className="form-label">Deduct From Reseller Wallet</Label>
                <button className="btn btn-md btn-primary d-flex" onClick={openDeductModel}> Click To Deduct</button>
            </Col>
        </Row>}
        {!profile && <div className={' d-flex justify-content-center align-items-center text-center p-5'}>
            <Spinner className={"text-center"}/>
        </div>}
        {profile && <Modal isOpen={openedModel} size={'md'} toggle={openDeductModel} centered>
            <ModalHeader className="border-4 pb-3">
                Deduct From {profile.resellerUsername} Wallet
            </ModalHeader>
            <ModalBody className="">
                <div className="">
                    <Label>Current Balance</Label>
                    <input type="text" className="form-control" disabled={true} readOnly={true} value={profile.resellerBalance ?? 0}/>
                </div>
                <div className="mt-2">
                    <Label>Balance To Deduct</Label>
                    <input type="text" className="form-control" placeholder="Amount" onChange={handleAmount}/>
                    {hasError && <small className="text-danger">Amount must be larger than 0 and less than {getSystemCurrency()} {profile.resellerBalance ?? 0}</small>}
                </div>
            </ModalBody>
            <ModalFooter className="border-4 pt-2">
                <div className="hstack gap-2 justify-content-end">
                    <button type="button" className="btn btn-danger" onClick={openDeductModel}>Cancel</button>
                    <button type="button" className="btn btn-primary" disabled={btnDisabled} onClick={deduct}>Deduct</button>
                </div>
            </ModalFooter>
        </Modal>}
    </>
}

export default ResellerDashboard