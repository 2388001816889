import { useEffect, useState } from 'react';
import {
    Container,
    Spinner,
    Pagination,
    PaginationItem,
    PaginationLink,
    Input,
    Label,
    Col, Row
} from "reactstrap";
import getApi from "../../../apis/get.api";
import { checkPermission, handleError, isMobileDevice } from 'helpers/commonFunctions';

const ListVoucher = () => {
    document.title = "Merchant | List Vouchers";
    const [vouchers, setVouchers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [voucherCode, setVoucherCode] = useState('');
    const canViewPage = checkPermission("/vouchers/list")

    const fetch = (page : number, code : string) => {
        setLoading(true);
        let url = `/merchant/voucher/list?page=${page}`;
        if (code) {
            url += `&code=${code}`;
        }
        getApi(url)
            .then((response) => {
                const data = response.data.data;
                setVouchers(data.data);
                setCurrentPage(data.current_page);
                setLastPage(data.last_page);
            })
            .catch((error) => {
                handleError(error)
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if(canViewPage){
            fetch(currentPage, voucherCode);
        }else{
            setLoading(false)
        }
    }, [currentPage, voucherCode,canViewPage]);

    const handlePageChange = (page : number) => {
        if (page !== currentPage) {
            setCurrentPage(page);
        }
    };

    const handleFilterChange = (e : any) => {
        setVoucherCode(e.target.value);
    };

    const handleFilterSubmit = (e : any) => {
        e.preventDefault();
        setCurrentPage(1);
        fetch(1, voucherCode);
    };

    return (
        <div className="page-content">
            <Container fluid={true}>
                {!canViewPage && <div className="display-5">You don't have permission to view this page</div>}
                {canViewPage && <div className="rounded-3">
                    <h1 className="display-5 fw-bold">Vouchers List</h1>
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header fs-4">
                                    In this page you will find all generated vouchers for your account, along with the status of each voucher
                                </div>
                                <div className="card-body">
                                    <Row className="align-items-center mb-5">
                                        <Col xs={12} lg={4}>
                                            <Label className="visually-hidden" htmlFor="inlineFormInputGroupUsername">Username</Label>
                                            <div className="input-group">
                                                <div className="input-group-text">Voucher Code</div>
                                                <Input type="text" className="form-control" id="voucherCode" value={voucherCode} onChange={handleFilterChange} placeholder="Voucher Code" />
                                            </div>
                                        </Col>
                                        <Col xs={12} lg={8} className={`text-end ${isMobileDevice() ? 'mt-3' : ''}`}>
                                            <button type="submit" className="btn btn-primary" onClick={handleFilterSubmit}>Search</button>
                                        </Col>
                                    </Row>
                                    <div className="table-responsive table-card">
                                        <table className="table align-middle table-nowrap table-striped-columns mb-0 fw-bold">
                                            <thead className="table-light">
                                            <tr className={"text-center"}>
                                                <th scope="col" className={"text-start"}>Voucher ID</th>
                                                <th scope="col">Voucher Code</th>
                                                <th scope="col">Voucher Amount</th>
                                                <th scope="col">Voucher Currency</th>
                                                <th scope="col">Voucher Status</th>
                                                <th scope="col">Voucher Redeemed Date</th>
                                                <th scope="col">Voucher Expiration Date</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                {loading && (
                                                    <tr>
                                                        <td className="text-center p-4" colSpan={7}><Spinner /></td>
                                                    </tr>
                                                )}
                                                {!loading && vouchers.length > 0 && vouchers.map((voucher : any) => (
                                                    <tr key={voucher.voucherId} className={"text-center fw-bold"}>
                                                        <td className={"text-start"}><span
                                                            className="fw-medium">#{voucher.voucherId}</span></td>
                                                        <td>{voucher.voucherCode}</td>
                                                        <td>{voucher.voucherAmount}</td>
                                                        <td>{voucher.voucherCurrency.toString().toUpperCase()}</td>
                                                        <td>{voucher.voucherStatus.toString().toUpperCase()}</td>
                                                        <td>{voucher.voucherRedeemedDate}</td>
                                                        <td>{voucher.voucherExpiryDate}</td>
                                                    </tr>
                                                ))}
                                                {!loading && vouchers.length === 0 && (
                                                    <tr>
                                                        <td className="text-center p-4" colSpan={7}>No Vouchers Created Yet</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className='card-footer'>
                                    <div className="card-footer d-flex p-0 justify-content-center mt-4 border-top-0">
                                        <Pagination>
                                            {/* Previous Button */}
                                            <PaginationItem disabled={currentPage === 1}>
                                                <PaginationLink previous
                                                                onClick={() => handlePageChange(currentPage - 1)}/>
                                            </PaginationItem>

                                            {/* Pagination Numbers */}
                                            {[...Array(lastPage)].map((_, i) => {
                                                // Display first two pages, last two pages, and pages near the current page
                                                const pageNumber = i + 1;
                                                const isNearCurrentPage = Math.abs(currentPage - pageNumber) <= 2;
                                                const isFirstOrLastPage = pageNumber === 1 || pageNumber === lastPage;

                                                if (isNearCurrentPage || isFirstOrLastPage) {
                                                    return (
                                                        <PaginationItem key={pageNumber}
                                                                        active={currentPage === pageNumber}>
                                                            <PaginationLink
                                                                onClick={() => handlePageChange(pageNumber)}>
                                                                {pageNumber}
                                                            </PaginationLink>
                                                        </PaginationItem>
                                                    );
                                                }
                                                // Ellipsis for skipped pages
                                                if (pageNumber === 2 && currentPage > 4) {
                                                    return <PaginationItem key="ellipsis1"
                                                                           disabled><PaginationLink>...</PaginationLink></PaginationItem>;
                                                }
                                                if (pageNumber === lastPage - 1 && currentPage < lastPage - 3) {
                                                    return <PaginationItem key="ellipsis2"
                                                                           disabled><PaginationLink>...</PaginationLink></PaginationItem>;
                                                }

                                                return null; // Don't render other page numbers
                                            })}

                                            {/* Next Button */}
                                            <PaginationItem disabled={currentPage === lastPage}>
                                                <PaginationLink next onClick={() => handlePageChange(currentPage + 1)}/>
                                            </PaginationItem>
                                        </Pagination>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </Container>
        </div>
    );
};

export default ListVoucher;