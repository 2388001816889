import React, { useEffect, useState } from "react";
import getApi from "../../../apis/get.api";
import {handleError, handleSuccess} from "../../../helpers/commonFunctions";
import {Label, Pagination, PaginationItem, PaginationLink, Spinner} from "reactstrap";
import Bar from "../../../Components/charts/Bar";

interface Props {
    resellerId: string | undefined;
    opened : boolean;
}

const ResellerCharts = ({ resellerId ,opened}: Props) => {
    const [categories, setCategories] = useState<any>();
    const [values, setValues] = useState<any>();
    const [loading,setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (resellerId && opened) {
            getList();
        }
    }, [resellerId,opened]);

    const getList = (queryParam = '') => {
        setLoading(true)
        getApi(`/merchant/reseller/vouchers/week/chart/${resellerId}`)
        .then((response) => {
            const data = response.data.data;
            setCategories(data.categories)
            setValues(data.values)
        })
        .catch((error) => {
            handleError(error);
        }).finally(() => {
            setLoading(false)
        })
    };


    return <>
        {loading && <div className={'d-flex justify-content-center align-items-center text-center p-5'}>
            <Spinner className={"text-center"}/>
        </div>}
        {!loading && categories && <Bar enableDataLabels={false} distributed={false} horizontal={false} categories={categories} values={values} dataColors={null} title={'Reseller Redeemed Vouchers For This Week'}/>}
    </>
};

export default ResellerCharts;
