import React, {useEffect, useState} from "react";
import {
    Card,
    CardBody,
    CardHeader,
    Container,
    Nav, NavItem, NavLink, TabContent, TabPane,
} from "reactstrap";
import classnames from "classnames";
import ResellerFundList from "./components/ResellerFundList";
import { useParams } from "react-router-dom";
import ResellerVouchersList from "./components/ResellerVouchersList";
import ResellerTransactionsList from "./components/ResellerTransactionsList";
import ResellerCharts from "./components/ResellerCharts";
import ResellerDashboard from "./components/ResellerDashboard";
import getApi from "../../apis/get.api";
import {handleError} from "../../helpers/commonFunctions";
const Reseller = () => {
    const { id } = useParams();
    const [activeTab, setActiveTab] = useState<string>('1');
    const [activityTab, setActivityTab] = useState<string>('1');
    const [profile, setProfile] = useState<any>(null);

    const toggleTab = (tab : any) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const toggleActivityTab = (tab : any) => {
        if (activityTab !== tab) {
            setActivityTab(tab);
        }
    };

    useEffect(() => {
        if(id){
            getReseller()
        }
    },[id])

    const getReseller = () => {
        getApi('/merchant/reseller/information/' + id).then(response => {
            setProfile(response.data.data)
        }).catch(error => {
            handleError(error)
        })
    }

    const forceUpdate = () => {
        getReseller()
    }

    return <div className="page-content">
        <Container fluid={true}>
            <div className="rounded-3">
                <Card className="card-height-100">
                    <CardHeader className="align-items-center d-flex">
                        <h4 className="card-title mb-0  me-2">{profile && profile.resellerUsername} Information</h4>
                        <div className="flex-shrink-0 ms-auto">
                            <Nav className="justify-content-end nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activityTab === '1' })}
                                        onClick={() => { toggleActivityTab('1'); }} >
                                        Reseller Dashboard
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activityTab === '2' })}
                                        onClick={() => { toggleActivityTab('2'); }} >
                                        Fund Transactions List
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activityTab === '3' })}
                                        onClick={() => { toggleActivityTab('3'); }} >
                                        Generated Voucher
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activityTab === '4' })}
                                        onClick={() => { toggleActivityTab('4'); }} >
                                        Transactions List
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activityTab === '5' })}
                                        onClick={() => { toggleActivityTab('5'); }} >
                                        General Information
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </div>
                    </CardHeader>
                    <CardBody className="">
                        <TabContent activeTab={activityTab} >
                            <TabPane tabId="1">
                                <ResellerDashboard resellerId={id} opened={activityTab === '1'} profile={profile} forceUpdate={forceUpdate}/>
                            </TabPane>
                            <TabPane tabId="2">
                                <ResellerFundList resellerId={id} opened={activityTab === '2'}/>
                            </TabPane>
                            <TabPane tabId="3">
                                <ResellerVouchersList resellerId={id} opened={activityTab === '3'}/>
                            </TabPane>
                            <TabPane tabId="4">
                                <ResellerTransactionsList resellerId={id} opened={activityTab === '4'}/>
                            </TabPane>
                            <TabPane tabId="5">
                                <ResellerCharts resellerId={id} opened={activityTab === '5'}/>
                            </TabPane>
                        </TabContent>
                    </CardBody>
                </Card>
            </div>
        </Container>
    </div>
}

export default Reseller