import React, { useEffect, useState } from "react";
import getApi from "../../../apis/get.api";
import {handleError, handleSuccess} from "../../../helpers/commonFunctions";
import {Label, Pagination, PaginationItem, PaginationLink, Spinner} from "reactstrap";

interface Props {
    resellerId: string | undefined;
    opened : boolean
}

const ResellerTransactionsList = ({ resellerId ,opened}: Props) => {
    const [list, setList] = useState<any>([]);
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");
    const [transactionId, setTransactionId] = useState<string>("");
    const [transactionAmount, setTransactionAmount] = useState<string>("");
    const [transactionType, setTransactionType] = useState<string>("");
    const [loading,setLoading] = useState<boolean>(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);

    useEffect(() => {
        if (resellerId && opened) {
            getList();
        }
    }, [resellerId,opened]);

    const getList = (queryParam = '') => {
        setLoading(true)
        getApi(`/merchant/reseller/transactions/${resellerId}?${queryParam}`)
            .then((response) => {
                const data = response.data.data.data;
                setList(data);
                setCurrentPage(response.data.data.current_page);
                setLastPage(response.data.data.last_page);
            })
            .catch((error) => {
                handleError(error);
            }).finally(() => {
            setLoading(false)
        })
    };

    const filterByData = (page ?: any) => {
        let query = '';

        if (startDate || endDate) {
            // Get today's date
            const today = new Date();
            const formattedToday = today.toISOString().split("T")[0]; // Format as YYYY-MM-DD

            // Calculate the date 3 months ago
            const threeMonthsAgo = new Date();
            threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
            const formattedThreeMonthsAgo = threeMonthsAgo.toISOString().split("T")[0]; // Format as YYYY-MM-DD

            // Use provided dates or defaults
            const start = startDate || formattedThreeMonthsAgo;
            const end = endDate || formattedToday;
            query = `&start=${start}&end=${end}`
        }

        if(transactionId) {
            let append = '';
            if(query){
                append = '&'
            }
            query = append + `transaction_id=` + transactionId
        }

        if(transactionAmount) {
            let append = '';
            if(query){
                append = '&'
            }
            query = append + `amount=` + transactionAmount
        }

        if(transactionType) {
            let append = '';
            if(query){
                append = '&'
            }
            query = append + `type=` + transactionType
        }

        if(page) {
            let append = '';
            if(query){
                append = '&'
            }
            query = append + `page=` + page
        }
        getList(query)
    };

    const clearFilter = () => {
        setTransactionAmount('')
        setStartDate('')
        setEndDate('')
        setTransactionId('')
        getList()
    }

    const exportResult = () => {
        let query = '';

        if (startDate || endDate) {
            // Get today's date
            const today = new Date();
            const formattedToday = today.toISOString().split("T")[0]; // Format as YYYY-MM-DD

            // Calculate the date 3 months ago
            const threeMonthsAgo = new Date();
            threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
            const formattedThreeMonthsAgo = threeMonthsAgo.toISOString().split("T")[0]; // Format as YYYY-MM-DD

            // Use provided dates or defaults
            const start = startDate || formattedThreeMonthsAgo;
            const end = endDate || formattedToday;
            query = `start=${start}&end=${end}`
        }

        if(transactionId) {
            let append = '';
            if(query){
                append = '&';
            }
            query = append + `transaction_id=` + transactionId
        }

        if(transactionAmount) {
            let append = '';
            if(query){
                append = '&';
            }
            query = append + `amount=` + transactionAmount
        }

        if(transactionType) {
            let append = '';
            if(query){
                append = '&'
            }
            query = append + `type=` + transactionType
        }

        exportResultsList(query)
    };

    const exportResultsList = (queryParam = '') => {
        getApi(`/merchant/reseller/transactions/export/${resellerId}?${queryParam}`)
            .then((response) => {
                handleSuccess(response)
            }).catch((error) => {
            handleError(error);
        })
    };

    const handlePageChange = (page : number) => {
        if (page !== currentPage) {
            setCurrentPage(page);
            filterByData(page)
        }
    };

    const getTransactionType = (transactionType : string,customTransaction : string) => {
        let type = ''
        switch (transactionType) {
            case 'fund':
                type = 'Fund Wallet';
                break;
            case 'deduct':
                type = 'Generate Voucher'
                break;
            default:
                type = customTransaction
                break;
        }
        return type;
    }

    return (
        <div>
            {/* Date Filter Inputs */}
            <div className="d-flex gap-4">
                <div>
                    <Label>Transaction ID</Label>
                    <input
                        type="text"
                        value={transactionId}
                        onChange={(e) => setTransactionId(e.target.value)}
                        className="form-control"
                        placeholder={"Transaction ID"}
                    />
                </div>
                <div>
                    <Label>Transaction Type</Label>
                    <select className={"form-control"} onChange={(e) => setTransactionType(e.target.value)}>
                        <option value={""} disabled={true}>Select</option>
                        <option value={"fund"}>Fund Wallet</option>
                        <option value={"deduct"}>Generate Voucher</option>
                    </select>
                </div>
                <div>
                    <Label>Date From</Label>
                    <input
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        className="form-control"
                    />
                </div>
                <div>
                    <Label>Date To</Label>
                    <input
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        className="form-control"
                        placeholder={"Date to"}
                    />
                </div>
                <div className={"d-flex flex-column"}>
                    <Label>Clear Filter</Label>
                    <button className="btn btn-danger" onClick={clearFilter}>
                        Clear Filter
                    </button>
                </div>
                <div className={"d-flex flex-column"}>
                    <Label>Apply Filter</Label>
                    <button className="btn btn-primary" onClick={() => {filterByData()}}>
                        Apply Filter
                    </button>
                </div>
                <div className={"d-flex flex-column"}>
                    <Label>Export Results</Label>
                    <button className="btn btn-success" onClick={exportResult}>
                        Export Results To CSV
                    </button>
                </div>
            </div>
            <div className="table-responsive table-card mt-3 fw-bold">
                <table className="table text-center align-middle table-nowrap table-striped-columns mb-0">
                    <thead className="table-light">
                    <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Transaction ID</th>
                            <th scope="col">Transaction Type</th>
                            <th scope="col">Transaction Currency</th>
                            <th scope="col">Transaction Amount</th>
                            <th scope="col">Reseller Balance Before Transaction</th>
                            <th scope="col">Reseller Balance After Transaction</th>
                            <th scope="col">Transaction Date</th>
                        </tr>
                    </thead>
                    <tbody>
                    {loading && <tr><td colSpan={8}><Spinner/></td></tr>}
                    {!loading && !list.length && (
                        <tr>
                            <td colSpan={8}>No Results</td>
                        </tr>
                    )}
                    {!loading && list.map((entity: any, index: number) => (
                        <tr key={index}>
                            <td>{entity.transactionUniqueId}</td>
                            <td>{entity.transactionId}</td>
                            <td>{getTransactionType(entity.transactionType,entity.transactionCustomType)}</td>
                            <td>{entity.transactionCurrency}</td>
                            <td className={entity.transactionType === 'deduct' ? 'text-danger' : 'text-success'}>{entity.transactionType === 'deduct' ? '-' : '+'}{entity.transactionAmount}</td>
                            <td>{entity.transactionAmountBefore}</td>
                            <td>{entity.transactionAmountAfter}</td>
                            <td>{entity.transactionDate}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <div className={"mt-5 text-center align-items-center justify-content-center"}>
                <Pagination>
                    {/* Previous Button */}
                    <PaginationItem disabled={currentPage === 1}>
                        <PaginationLink previous
                                        onClick={() => handlePageChange(currentPage - 1)}/>
                    </PaginationItem>

                    {/* Pagination Numbers */}
                    {[...Array(lastPage)].map((_, i) => {
                        // Display first two pages, last two pages, and pages near the current page
                        const pageNumber = i + 1;
                        const isNearCurrentPage = Math.abs(currentPage - pageNumber) <= 2;
                        const isFirstOrLastPage = pageNumber === 1 || pageNumber === lastPage;

                        if (isNearCurrentPage || isFirstOrLastPage) {
                            return (
                                <PaginationItem key={pageNumber}
                                                active={currentPage === pageNumber}>
                                    <PaginationLink
                                        onClick={() => handlePageChange(pageNumber)}>
                                        {pageNumber}
                                    </PaginationLink>
                                </PaginationItem>
                            );
                        }
                        // Ellipsis for skipped pages
                        if (pageNumber === 2 && currentPage > 4) {
                            return <PaginationItem key="ellipsis1"
                                                   disabled><PaginationLink>...</PaginationLink></PaginationItem>;
                        }
                        if (pageNumber === lastPage - 1 && currentPage < lastPage - 3) {
                            return <PaginationItem key="ellipsis2"
                                                   disabled><PaginationLink>...</PaginationLink></PaginationItem>;
                        }

                        return null; // Don't render other page numbers
                    })}

                    {/* Next Button */}
                    <PaginationItem disabled={currentPage === lastPage}>
                        <PaginationLink next onClick={() => handlePageChange(currentPage + 1)}/>
                    </PaginationItem>
                </Pagination>
            </div>
        </div>
    );
};

export default ResellerTransactionsList;
