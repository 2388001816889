import React, {useEffect, useState} from 'react';
import {
    Container,
    Input,
    Label,
    Spinner,
    Card, CardHeader, CardBody, CardFooter,
    ButtonGroup,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledButtonDropdown
} from "reactstrap";
import Select from "react-select";
import getApi from "../../../apis/get.api";
import postApi from "../../../apis/post.api";
import { checkPermission, handleError, handleSuccess, isMobileDevice } from 'helpers/commonFunctions';

const FundWallet = () => {
    document.title = "Merchant | Fund Reseller Wallet";
    const [resellers,setResellers] = useState(null)
    const [transactions,setTransactions] = useState([])
    const [disabledFundButton,setDisabledFundButton] = useState(false)
    const [sourceTransactionId,setSourceTransactionId] = useState<any>('')
    const [reseller,setReseller] = useState(null)
    const [amount,setAmount] = useState<any>(null)
    const [loading,setLoading] = useState(false)
    const [currency,setCurrency] = useState<any>('USD')
    const canViewPage = checkPermission("/resellers/wallet/fund");
    const [fundLoading,setFundLoading] = useState<boolean>(false)

    useEffect(() => {
        if(canViewPage){
            fetch();
        }else{
            setLoading(false)
        }

        if(canViewPage){
            fetchReseller();
        }
    },[canViewPage])
    
    const fetchReseller = () => {
        getApi('/merchant/resellers/fund/list').then((response : any) => {
            let resellersList : any = [];
            response.data.data.forEach((reseller : any) => {
                resellersList.push({
                    label : reseller.resellerName,
                    value : reseller.resellerId
                })
            })
            setResellers(resellersList)
        }).catch((error : any) => {
            handleError(error)
        })
    }

    const fetch = () => {
        getApi('/merchant/reseller/wallet/fund').then((response : any) => {
            let transactions : any = [];
            if(response.data.data){
                response.data.data.forEach((transaction : any) => {
                    transactions.push({
                        transactionId : transaction.transactionId,
                        transactionType : transaction.transactionType,
                        transactionSourceTransactionId : transaction.transactionSourceTransactionId,
                        transactionAmount : transaction.transactionAmount,
                        transactionCurrency : transaction.transactionCurrency,
                        transactionAmountBefore : transaction.transactionAmountBefore,
                        transactionAmountAfter : transaction.transactionAmountAfter,
                        transactionDate : transaction.transactionDate,
                        resellerName : transaction.resellerName,
                        resellerEmail : transaction.resellerEmail
                    })
                })
            }
            setTransactions(transactions)
        }).catch((error : any) => {
            handleError(error)
        })
    }

    const handleAmount = (event : any) => {
        var amountValue : any = parseFloat(event.target.value)
        if(event.target.value === ''){
            amountValue = '';
        }
        setAmount(amountValue);
    }

    const handleSourceTransactionId = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const digitsOnly = value.replace(/\D/g, ''); // Only allow digits
        const sourceTransaction = digitsOnly ? parseFloat(digitsOnly) : 0;
        if (digitsOnly) {
            setSourceTransactionId(sourceTransaction);
        }
        if(!digitsOnly){
            setSourceTransactionId('');
        }
    };


    const handleReseller = (event : any) => {
        setReseller(event.value)
    }

    useEffect(() => {
        if(amount && sourceTransactionId && reseller){
            setDisabledFundButton(false)
        }else{
            setDisabledFundButton(true)
        }
    }, [amount,sourceTransactionId,reseller]);

    const fundWallet = () => {
        setFundLoading(true)
        const payload = {
            resellerId : reseller,
            amount : amount,
            sourceTransactionId : sourceTransactionId,
            currency : currency
        };
        postApi('/merchant/reseller/wallet/fund',payload).then((response : any)  => {
            handleSuccess(response)
            setAmount('')
            setSourceTransactionId('')
            setFundLoading(false)
            fetch();
        }).catch((error : any) => {
            handleError(error)
            setFundLoading(false)
        })
    }

    return <div className="page-content">
        <Container fluid={true}>
            <div className="rounded-3">
                <h1 className="display-5 fw-bold">Fund My Resellers Wallets</h1>
                <div className="row justify-content-center">
                    <div className={`${canViewPage ? 'col-lg-9' : 'col-lg-12' }`}>
                        <div className="card h-100">
                            <div className="card-header">
                                Latest 10 Wallet Funds
                            </div>
                            <div className="table-responsive table-card card-body">
                                <table className="table align-middle table-nowrap table-striped-columns mb-0 fw-bold">
                                    <thead className="table-light">
                                    <tr className="text-center">
                                        <th scope="col">Reseller Name</th>
                                        <th scope="col">Reseller Email</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Currency</th>
                                        <th scope="col">Amount Before</th>
                                        <th scope="col">Amount After</th>
                                        <th scope="col">Date</th>
                                    </tr>
                                    </thead>
                                    <tbody className="text-center">
                                    {transactions && transactions.length > 0 && canViewPage && transactions.map((transaction: any) => {
                                        return <tr className={"fw-bold"}>
                                            <td>{transaction.resellerName}</td>
                                            <td>{transaction.resellerEmail}</td>
                                            <td>{transaction.transactionAmount}</td>
                                            <td>{transaction.transactionCurrency}</td>
                                            <td>{transaction.transactionAmountBefore}</td>
                                            <td>{transaction.transactionAmountAfter}</td>
                                            <td>{transaction.transactionDate}</td>
                                        </tr>
                                    })}
                                    {transactions && transactions.length === 0 && canViewPage && <tr>
                                        <td className="text-center p-4" colSpan={7}>No Data Found</td>
                                    </tr>}
                                    {loading && <tr>
                                        <td className="text-center p-4" colSpan={7}><Spinner/></td>
                                    </tr>}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    {canViewPage && <div className={`${canViewPage ? 'col-lg-3' : 'col-lg-12' }`} >
                        <Card tabs className={`nav-tabs  nav-justified mb-3 bg-white ${isMobileDevice() ? 'mt-3' : ''}`}>
                            <CardHeader>
                                Fund Reseller Wallet
                            </CardHeader>
                            <CardBody>
                                <div className="mt-2">
                                    <h6>Select Reseller To Fund</h6>
                                    <Select
                                        options={resellers}
                                        id="choices-single-default"
                                        className="js-example-basic-single mb-0 z-2"
                                        name="merchant"
                                        onChange={handleReseller}
                                    />
                                </div>
                                <div className="mt-2">
                                    <Label>Fund Amount</Label>
                                    <div className="input-group">
                                        <ButtonGroup className="z-1">
                                            <UncontrolledButtonDropdown id="btnGroupDrop1">
                                                <DropdownToggle color="primary" caret size={'sm'}>
                                                    {currency}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem onClick={() => {
                                                        setCurrency('USD')
                                                    }}> USD</DropdownItem>
                                                    <DropdownItem onClick={() => {
                                                        setCurrency('MAD')
                                                    }}> MAD </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </ButtonGroup>
                                        <Input type="number" min={0.01} max={9999} value={amount}
                                               onChange={handleAmount}
                                               className="form-control" id="autoSizingInputGroup"
                                               placeholder="Fund Amount"/>
                                    </div>
                                </div>
                                <div className="mt-2">
                                    <Label>Bank Transaction ID Or USDT Transaction ID</Label>
                                    <div className="input-group">
                                        <Input type="text" value={sourceTransactionId}
                                               onChange={handleSourceTransactionId}
                                               className="form-control" id="autoSizingInputGroup"
                                               placeholder="Source Transaction ID"/>
                                    </div>
                                </div>
                            </CardBody>
                            {resellers && <CardFooter>
                                <div className={`mt-3 ${fundLoading ? 'text-center' : ''}`}>
                                    {!fundLoading &&
                                        <button className="btn btn-primary w-100" onClick={fundWallet}
                                                disabled={disabledFundButton}>Fund Reseller Wallet</button>}
                                    {fundLoading && <Spinner/>}
                                </div>
                            </CardFooter>}
                            {!resellers && <div className="text-center m-5"><Spinner/></div>}
                        </Card>
                    </div>}
                </div>
            </div>
        </Container>
    </div>
}

export default FundWallet;