
// @ts-nocheck

import { configureStore } from "@reduxjs/toolkit";
import axios from "axios";
import { removeLoginStorage, showLoginPopup } from "helpers/api_helper";
import { open2faPopupFunction, openPopupFunction } from "slices/loginPopup/thunk";
import store from "store";

interface IRequest {
    get: (url: string) => Promise < any > 
    delete: (url: string) => Promise < any > 
    post: (url: string, body: any) => Promise < any >
    put: (url: string, body: any) => Promise < any >
}

export default class Api {
    static getInstance():{request:IRequest} {
        const originalAxios = axios.create({
            baseURL:  process.env.REACT_APP_API_URL,
            timeout: Number(process.env.REACT_APP_API_TIMEOUT || '5000')
        });
        const defaultHeaders = {
            'accept-language': 'ar',
            'Content-Type' : 'application/json',
            'Authorization' : 'Bearer ' + localStorage.getItem("merchant_access_token"),
            'Currency' : localStorage.getItem("currency") ?? 'USD'
        };

        const request: IRequest = {
            get: async function (url: string) {
                return originalAxios.get(url, {
                    headers: defaultHeaders,
                    withCredentials: true,
                    timeout: 10000
                }).catch(error => {
                    if(error.response && error.response.status == 403){
                        window.location.href = "/403"
                    }else if(error.response && error.response.status == 401){
                        removeLoginStorage();
                        window.location.href = "/login"
                    }else if(error.response && error.response.status == 400){
                        window.location.href = "/email/verify";
                    }
                    throw error;
                });
            },
            delete: async function (url: string) {
                return originalAxios.delete(url, {
                    headers: defaultHeaders,
                    withCredentials: true
                }).catch(error => {
                    if(error.response && error.response.status == 403){
                        window.location.href = "/403"
                    }else if(error.response && error.response.status == 401){
                        removeLoginStorage();
                        window.location.href = "/login"
                    }else if(error.response && error.response.status == 400){
                        window.location.href = "/email/verify";
                    }
                    throw error;
                });
            },
            post: async function (url: string, data:any) {
                const response = await originalAxios.post(url, data,{
                    headers: defaultHeaders,
                    withCredentials: true
                }).catch(error => {
                    if(error.response && error.response.status == 403){
                        window.location.href = "/403"
                    }else if(error.response && error.response.status == 401){
                        removeLoginStorage();
                        window.location.href = "/login"
                    }else if(error.response && error.response.status == 400){
                        window.location.href = "/email/verify";
                    }
                    throw error;
                });
                return response;
            },
            put: async function (url: string, data: any) {
                const response = await originalAxios.put(url, data,{
                    headers: defaultHeaders,
                    withCredentials: true
                }).catch(error => {
                    if(error.response && error.response.status == 403){
                        window.location.href = "/403"
                    }else if(error.response && error.response.status == 401){
                        removeLoginStorage();
                        window.location.href = "/login"
                    }else if(error.response && error.response.status == 400){
                        window.location.href = "/email/verify";
                    }
                    throw error;
                });
                return response;
            },
        }
        return {
            request
        }
    }


}